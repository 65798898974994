import { createSelector } from 'reselect'

import { Result, ResultError } from '@app/packages/Result/Result'

import { StoreState } from '@app/store/store'

import { intoSelectorResult } from './SelectorResult'

/** BasicSelector */
type BS<R> = (state: StoreState) => R
/** ResultValue */
type RV<T> = T extends ResultError ? never : T extends Result<infer V> ? RV<V> : T

export function createSelectorResult<S1, R>(selectors: [BS<S1>], sel: (s1: RV<S1>) => R): BS<Result<R>>
export function createSelectorResult<S1, S2, R>(selectors: [BS<S1>, BS<S2>], sel: (s1: RV<S1>, s2: RV<S2>) => R): BS<Result<R>>
export function createSelectorResult<S1, S2, S3, R>(selectors: [BS<S1>, BS<S2>, BS<S3>], sel: (s1: RV<S1>, s2: RV<S2>, s3: RV<S3>) => R): BS<Result<R>>
export function createSelectorResult<S1, S2, S3, S4, R>(
  selectors: [BS<S1>, BS<S2>, BS<S3>, BS<S3>],
  sel: (s1: RV<S1>, s2: RV<S2>, s3: RV<S3>, s4: RV<S4>) => R
): BS<Result<R>>
export function createSelectorResult<S1, S2, S3, S4, S5, R>(
  selectors: [BS<S1>, BS<S2>, BS<S3>, BS<S4>, BS<S5>],
  sel: (s1: RV<S1>, s2: RV<S2>, s3: RV<S3>, s4: RV<S4>, s5: RV<S5>) => R
): BS<Result<R>>
export function createSelectorResult<S1, S2, S3, S4, S5, S6, R>(
  selectors: [BS<S1>, BS<S2>, BS<S3>, BS<S4>, BS<S5>, BS<S6>],
  sel: (s1: RV<S1>, s2: RV<S2>, s3: RV<S3>, s4: RV<S4>, s5: RV<S5>, s6: RV<S6>) => R
): BS<Result<R>>
export function createSelectorResult<S1, S2, S3, S4, S5, S6, S7, R>(
  selectors: [BS<S1>, BS<S2>, BS<S3>, BS<S4>, BS<S5>, BS<S6>, BS<S7>],
  sel: (s1: RV<S1>, s2: RV<S2>, s3: RV<S3>, s4: RV<S4>, s5: RV<S5>, s6: RV<S6>, s7: RV<S7>) => R
): BS<Result<R>>
export function createSelectorResult<S1, S2, S3, S4, S5, S6, S7, S8, R>(
  selectors: [BS<S1>, BS<S2>, BS<S3>, BS<S4>, BS<S5>, BS<S6>, BS<S7>, BS<S8>],
  sel: (s1: RV<S1>, s2: RV<S2>, s3: RV<S3>, s4: RV<S4>, s5: RV<S5>, s6: RV<S6>, s7: RV<S7>, s8: RV<S8>) => R
): BS<Result<R>>
export function createSelectorResult<S1, S2, S3, S4, S5, S6, S7, S8, S9, R>(
  selectors: [BS<S1>, BS<S2>, BS<S3>, BS<S4>, BS<S5>, BS<S6>, BS<S7>, BS<S8>, BS<S9>],
  sel: (s1: RV<S1>, s2: RV<S2>, s3: RV<S3>, s4: RV<S4>, s5: RV<S5>, s6: RV<S6>, s7: RV<S7>, s8: RV<S8>, s9: RV<S9>) => R
): BS<Result<R>>
export function createSelectorResult<S1, S2, S3, S4, S5, S6, S7, S8, S9, S10, R>(
  selectors: [BS<S1>, BS<S2>, BS<S3>, BS<S4>, BS<S5>, BS<S6>, BS<S7>, BS<S8>, BS<S9>, BS<S10>],
  sel: (s1: RV<S1>, s2: RV<S2>, s3: RV<S3>, s4: RV<S4>, s5: RV<S5>, s6: RV<S6>, s7: RV<S7>, s8: RV<S8>, s9: RV<S9>, s10: RV<S10>) => R
): BS<Result<R>>
export function createSelectorResult<S1, S2, S3, S4, S5, S6, S7, S8, S9, S10, S11, R>(
  selectors: [BS<S1>, BS<S2>, BS<S3>, BS<S4>, BS<S5>, BS<S6>, BS<S7>, BS<S8>, BS<S9>, BS<S10>, BS<S11>],
  sel: (s1: RV<S1>, s2: RV<S2>, s3: RV<S3>, s4: RV<S4>, s5: RV<S5>, s6: RV<S6>, s7: RV<S7>, s8: RV<S8>, s9: RV<S9>, s10: RV<S10>, s11: RV<S11>) => R
): BS<Result<R>>
export function createSelectorResult<S1, S2, S3, S4, S5, S6, S7, S8, S9, S10, S11, S12, R>(
  selectors: [BS<S1>, BS<S2>, BS<S3>, BS<S4>, BS<S5>, BS<S6>, BS<S7>, BS<S8>, BS<S9>, BS<S10>, BS<S11>, BS<S12>],
  sel: (
    s1: RV<S1>,
    s2: RV<S2>,
    s3: RV<S3>,
    s4: RV<S4>,
    s5: RV<S5>,
    s6: RV<S6>,
    s7: RV<S7>,
    s8: RV<S8>,
    s9: RV<S9>,
    s10: RV<S10>,
    s11: RV<S11>,
    s12: RV<S12>
  ) => R
): BS<Result<R>>
export function createSelectorResult<S1, S2, S3, S4, S5, S6, S7, S8, S9, S10, S11, S12, S13, R>(
  selectors: [BS<S1>, BS<S2>, BS<S3>, BS<S4>, BS<S5>, BS<S6>, BS<S7>, BS<S8>, BS<S9>, BS<S10>, BS<S11>, BS<S12>, BS<S13>],
  sel: (
    s1: RV<S1>,
    s2: RV<S2>,
    s3: RV<S3>,
    s4: RV<S4>,
    s5: RV<S5>,
    s6: RV<S6>,
    s7: RV<S7>,
    s8: RV<S8>,
    s9: RV<S9>,
    s10: RV<S10>,
    s11: RV<S11>,
    s12: RV<S12>,
    s13: RV<S13>
  ) => R
): BS<Result<R>>
export function createSelectorResult<S1, S2, S3, S4, S5, S6, S7, S8, S9, S10, S11, S12, S13, S14, R>(
  selectors: [BS<S1>, BS<S2>, BS<S3>, BS<S4>, BS<S5>, BS<S6>, BS<S7>, BS<S8>, BS<S9>, BS<S10>, BS<S11>, BS<S12>, BS<S13>, BS<S14>],
  sel: (
    s1: RV<S1>,
    s2: RV<S2>,
    s3: RV<S3>,
    s4: RV<S4>,
    s5: RV<S5>,
    s6: RV<S6>,
    s7: RV<S7>,
    s8: RV<S8>,
    s9: RV<S9>,
    s10: RV<S10>,
    s11: RV<S11>,
    s12: RV<S12>,
    s13: RV<S13>,
    s14: RV<S14>
  ) => R
): BS<Result<R>>
export function createSelectorResult(cbs: any, sel: (...args: any[]) => any) {
  return createSelector(cbs, (...args) =>
    intoSelectorResult(() => {
      const unwrappedArgs = args.map(a => unwrapResult(a))
      return sel(...unwrappedArgs)
    })
  ) as any
}

const unwrapResult = <T>(r: T): RV<T> => {
  if (r && typeof r === 'object' && 'error' in r && typeof r.error === 'boolean' && 'value' in r) {
    if (r.error) throw r.value
    return unwrapResult(r.value) as any
  }
  return r as any
}

import { createSelector } from 'reselect'

import { StoreState } from '@app/store/store'

export const userSelector = (state: StoreState) => state.profile.user
export const userDataSelector = createSelector([userSelector], user => {
  if (user?.account_type === 'parent') return { account_type: 'parent', registration_completed: user.registration_completed } as const
  if (user?.account_type === 'sitter')
    return {
      account_type: 'sitter',
      registration_completed: user.registration_completed,
      approved: user.approved,
      training_completed: user.training_completed,
    } as const
  return null
})

export const isQualifiedSitterSelector = createSelector([userDataSelector], user => {
  if (!user) return false
  if (user.account_type !== 'sitter') return false
  return user.approved || user.training_completed
})

export const isCandidateSitterSelector = createSelector([userDataSelector], user => {
  if (!user) return false
  if (user.account_type !== 'sitter') return false
  if (user.approved || user.training_completed) return false
  return true
})

/* THIS IS AUTOGENERATED FILE. DO NOT EDIT */
/* see readme.md */
/* eslint-disable */
// prettier-ignore-start

import { AsyncRoute } from '@app/utils/routing/types'

// @routes declaration
const routes = (): AsyncRoute => ({
  component: () => import('./Layout').then(m => m.default),
  routes: [
    {
      path: '/__polygon',
      event_id: 'polygon_index',
      component: async () => () => null,
    },
    {
      path: '/__polygon/Accordion',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/Accordion/Accordion.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/AccountTypeSelect',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/AccountTypeSelect/AccountTypeSelect.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/ActionButtonsModal',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/ActionButtonsModal/ActionButtonsModal.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/AddressInput',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/AddressInput/AddressInput.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/AddressMap',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/AddressMap/AddressMap.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/AnnouncementAutoAcceptConfirm',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/AnnouncementAutoAcceptConfirm/AnnouncementAutoAcceptConfirm.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/AnnouncementCard',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/AnnouncementCard/AnnouncementCard.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/AnnouncementCard/AnnouncementDetailsModal',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/AnnouncementCard/AnnouncementDetailsModal.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/AnnouncementCard/BecomeBabysitterModal',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/AnnouncementCard/BecomeBabysitterModal.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/AnnouncementCard/ParentsAnnouncementCard',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/AnnouncementCard/ParentsAnnouncementCard.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/AnnouncementCard/SittersAnnouncementCard',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/AnnouncementCard/SittersAnnouncementCard.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/AnnouncementFeatureLabel',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/AnnouncementFeatureLabel/AnnouncementFeatureLabel.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/AnnouncementFeatureModal',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/AnnouncementFeatureModal/AnnouncementFeatureModal.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/AnnouncementForm',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/AnnouncementForm/AnnouncementForm.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/AnnouncementPriceModeSelect',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/AnnouncementPriceModeSelect/AnnouncementPriceModeSelect.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/AnnouncementResponseDetailsModal',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/AnnouncementResponseDetailsModal/AnnouncementResponseDetailsModal.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/AnnouncementsFilter/AnnouncementCard',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/AnnouncementsFilter/AnnouncementCard.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/AnnouncementsFilter/AnnouncementCardListModal',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/AnnouncementsFilter/AnnouncementCardListModal.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/AnnouncementsFilter/AnnouncementsMap',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/AnnouncementsFilter/AnnouncementsMap.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/AnnouncementsFilter/FiltersForm',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/AnnouncementsFilter/FiltersForm.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/AppStoreCustomerReviewGallery',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/AppStoreCustomerReviewGallery/AppStoreCustomerReviewGallery.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/AppStoreIcons',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/AppStoreIcons/AppStoreIcons.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/AttributeLabel',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/AttributeLabel/AttributeLabel.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/AuthForm',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/AuthForm/AuthForm.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/AuthForm/CodeScreen',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/AuthForm/CodeScreen.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/AuthForm/DeliveryNotesScreen',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/AuthForm/DeliveryNotesScreen.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/AuthForm/EmailScreen',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/AuthForm/EmailScreen.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/AuthForm/PhoneChangeForm',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/AuthForm/PhoneChangeForm.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/AuthForm/PhoneScreen',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/AuthForm/PhoneScreen.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/AuthForm/SelectScreen',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/AuthForm/SelectScreen.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/AutoAcceptAnnouncementConfirm',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/AutoAcceptAnnouncementConfirm/AutoAcceptAnnouncementConfirm.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/AutoPagingView',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/AutoPagingView/AutoPagingView.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/Button',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/Button/Button.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/CardModal',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/CardModal/CardModal.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/CityInput',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/CityInput/CityInput.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/CompactSitterCard',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/CompactSitterCard/CompactSitterCard.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/ComplaintModal',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/ComplaintModal/ComplaintModal.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/ControlClasses',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/ControlClasses/ControlClasses.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/Countdown/CountDown',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/Countdown/CountDown.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/CurrencySelect',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/CurrencySelect/CurrencySelect.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/CurrencySelectModal',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/CurrencySelectModal/CurrencySelectModal.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/DatePicker',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/DatePicker/DatePicker.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/DebtsInfoCommon/DebtsInfoBanner',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/DebtsInfoCommon/DebtsInfoBanner.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/DebtsModal',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/DebtsModal/DebtsModal.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/DiameterSelectModal',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/DiameterSelectModal/DiameterSelectModal.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/DirectAnnouncementCancelModal',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/DirectAnnouncementCancelModal/DirectAnnouncementCancelModal.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/DirectAnnouncementDetailsModal',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/DirectAnnouncementDetailsModal/DirectAnnouncementDetailsModal.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/DotsLoader',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/DotsLoader/DotsLoader.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/Dropdown',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/Dropdown/Dropdown.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/Endless',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/Endless/Endless.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/ErrorModal',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/ErrorModal/ErrorModal.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/ErrorRenderer',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/ErrorRenderer/ErrorRenderer.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/FormCheckbox',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/FormCheckbox/FormCheckbox.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/FormDatePicker',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/FormDatePicker/FormDatePicker.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/FormSelect',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/FormSelect/FormSelect.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/Icon',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/Icon/Icon.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/IncomeCalculator',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/IncomeCalculator/IncomeCalculator.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/IncomeCalculator/SimpleIncomeCalculator',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/IncomeCalculator/SimpleIncomeCalculator.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/InfoBanner',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/InfoBanner/InfoBanner.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/Input',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/Input/Input.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/InputButton',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/InputButton/InputButton.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/LayoutMilk/PlaceInput',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/LayoutMilk/PlaceInput.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/LoaderInitiator',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/LoaderInitiator/LoaderInitiator.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/Login/LoginModal',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/Login/LoginModal.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/Login/NewUser',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/Login/NewUser.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/MarkCheckbox',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/MarkCheckbox/MarkCheckbox.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/MegafonModal',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/MegafonModal/MegafonModal.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/Menu',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/Menu/Menu.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/Modal',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/Modal/Modal.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/ModalV2/Modal',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/ModalV2/Modal.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/NewDirectAnnouncementSuccess',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/NewDirectAnnouncementSuccess.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/NewSitterRequest',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/NewSitterRequest/NewSitterRequest.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/OfferPreview',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/OfferPreview/OfferPreview.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/OpacityTransitionList',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/OpacityTransitionList/OpacityTransitionList.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/ParentRegistrationForm',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/ParentRegistrationForm/ParentRegistrationForm.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/ParentSubscriptionStatusModal',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/ParentSubscriptionStatusModal/ParentSubscriptionStatusModal.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/ParentSubscriptionStatusSelect',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/ParentSubscriptionStatusSelect/ParentSubscriptionStatusSelect.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/PaymentElaborationModal',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/PaymentElaborationModal/PaymentElaborationModal.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/PaymentErrorModal',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/PaymentErrorModal/PaymentErrorModal.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/PerksList',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/PerksList/PerksList.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/PerksList/PerksListEditor',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/PerksList/PerksListEditor.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/PhoneInput',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/PhoneInput/PhoneInput.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/PlaceInput',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/PlaceInput/PlaceInput.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/PlaceInputModal',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/PlaceInputModal/PlaceInputModal.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/PointsMap',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/PointsMap/PointsMap.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/PriceInput',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/PriceInput/PriceInput.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/PromocodeModal',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/PromocodeModal/PromocodeModal.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/Range',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/Range/Range.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/Range/Slider',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/Range/Slider.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/ReferralBlock',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/ReferralBlock/ReferralBlock.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/ReferralBonusBlock',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/ReferralBonusBlock/ReferralBonusBlock.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/RejectRequest',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/RejectRequest.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/RequestCard',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/RequestCard/RequestCard.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/RequestDetailsModal',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/RequestDetailsModal/RequestDetailsModal.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/Review',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/Review/Review.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/Review/ReviewQA',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/Review/ReviewQA.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/ReviewSuccess',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/ReviewSuccess/ReviewSuccess.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/SavedAnnouncementSearchViewModal',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/SavedAnnouncementSearchViewModal/SavedAnnouncementSearchViewModal.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/SavedAnnouncementsFilterEditModal/AverageAnnouncementsCount',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/SavedAnnouncementsFilterEditModal/AverageAnnouncementsCount.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/SavedAnnouncementsFilterEditModal',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/SavedAnnouncementsFilterEditModal/SavedAnnouncementsFilterEditModal.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/SavedAnnouncementsSearchesEditorModal',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/SavedAnnouncementsSearchesEditorModal/SavedAnnouncementsSearchesEditorModal.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/ScheduleCalendar',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/ScheduleCalendar/ScheduleCalendar.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/ScheduleCalendarMonth',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/ScheduleCalendarMonth/ScheduleCalendarMonth.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/ScheduleCalendarWeek',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/ScheduleCalendarWeek/ScheduleCalendarWeek.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/ScheduleEditModal',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/ScheduleEditModal/ScheduleEditModal.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/ScheduleEditModal/SlotEditModal',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/ScheduleEditModal/SlotEditModal.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/SecurityClearanceModal',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/SecurityClearanceModal/SecurityClearanceModal.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/SegmentedControl',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/SegmentedControl/SegmentedControl.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/Select',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/Select/Select.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/SliderGallery',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/SliderGallery/SliderGallery.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/SlideUp',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/SlideUp/SlideUp.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/SocialNetworksInfoModal',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/SocialNetworksInfoModal/SocialNetworksInfoModal.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/SpinnerIcon',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/SpinnerIcon/SpinnerIcon.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/StickyPanel',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/StickyPanel/StickyPanel.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/SuccessMessage/GenericSuccessMessageModal',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/SuccessMessage/GenericSuccessMessageModal.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/SuccessMessage',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/SuccessMessage/SuccessMessage.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/SuggestInput/ControlledSuggestInput',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/SuggestInput/ControlledSuggestInput.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/SuggestInput',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/SuggestInput/SuggestInput.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/SwiperDots',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/SwiperDots/SwiperDots.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/Tabs',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/Tabs/Tabs.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/TagsList',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/TagsList/TagsList.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/TextArea',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/TextArea/TextArea.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/TimeSelect',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/TimeSelect/TimeSelect.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/TimezoneSelect',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/TimezoneSelect/TimezoneSelect.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/Tooltip',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/Tooltip/Tooltip.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/TOSAccept',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/TOSAccept/TOSAccept.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/TrainingRequestComplete',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/TrainingRequestComplete/TrainingRequestComplete.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/TrainingRequestForm',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/TrainingRequestForm/TrainingRequestForm.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/Twemoji',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/Twemoji/Twemoji.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/Typography',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/Typography/Typography.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/UserCard',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/UserCard/UserCard.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/UserProfile/ParentProfile',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/UserProfile/ParentProfile.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/UserProfile/SitterProfile',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/components/UserProfile/SitterProfile.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/hooks/usePushNotifications',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/hooks/usePushNotifications.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/routes/Academy/CartItem',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/routes/Academy/CartItem.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/routes/Academy/CartItems',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/routes/Academy/CartItems.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/routes/Academy/CourseCard',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/routes/Academy/CourseCard.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/routes/Academy/CoursesGallery',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/routes/Academy/CoursesGallery.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/routes/Academy/LessonCard',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/routes/Academy/LessonCard.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/routes/Academy/PromocodeInput',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/routes/Academy/PromocodeInput.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/routes/Academy/PurchasedCard',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/routes/Academy/PurchasedCard.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/routes/Academy/PurchasedLoginForm',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/routes/Academy/PurchasedLoginForm.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/routes/Academy/VideoCard',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/routes/Academy/VideoCard.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/routes/Affiliate/AffiliateCard',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/routes/Affiliate/AffiliateCard.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/routes/Announcements/AnnouncementSuccess',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/routes/Announcements/AnnouncementSuccess.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/routes/CandidateSitter/Header',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/routes/CandidateSitter/Header.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/routes/Messages/SitterCard',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/routes/Messages/SitterCard.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/routes/NotificationSettings',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/routes/NotificationSettings/NotificationSettings.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/routes/Playground/PlaygroundForm',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/routes/Playground/PlaygroundForm.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/routes/Quiz/QuizCard',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/routes/Quiz/QuizCard.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/routes/Search/SearchMap',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/routes/Search/SearchMap.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/routes/Settings/Calendar',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/routes/Settings/Calendar.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/routes/Settings/RegistrationComplete',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/routes/Settings/RegistrationComplete.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/routes/Settings/TrainingConfirmation',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/routes/Settings/TrainingConfirmation.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/routes/Static/InView',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/routes/Static/components/InView/InView.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/routes/Static/LandingJuly/ButtonType',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/routes/Static/components/LandingJuly/ButtonType.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/routes/Static/LandingParent/SitterGalleryCard',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/routes/Static/components/LandingParent/SitterGalleryCard.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/routes/Static/LandingSitter/Typography',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/routes/Static/components/LandingSitter/Typography.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/services/Progress',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/services/Progress/Progress.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/utils/diagnostics',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/utils/diagnostics.polygon').then(m => m.default),
    },
    {
      path: '/__polygon/utils/RaiiGuard',
      event_id: 'polygon_page',
      component: () => import(/* webpackChunkName: "routes_polygon" */ '@app/utils/RaiiGuard.polygon').then(m => m.default),
    },
  ],
})

export default routes

// prettier-ignore-end

import { TaskState } from '@app/types/tasks'

import { Task } from '@app/packages/task/Task'

import { setTask } from '@app/store/actions/tasks'
import { createReducer } from '@app/store/toolkit'

/** Task gets converted to TaskState on serialization */
export default createReducer<{ [key: string]: Task<any, any> | TaskState }>({}, builder => {
  builder.addCase(setTask.shape, (state, action) => {
    return { ...state, [action.payload.label]: action.payload.task }
  })
})

import { ApiFaq } from '@app/constants/ApiTypes/entities'
import { ApiDataResponse } from '@app/constants/ApiTypes/misc'
import { FaqTaskSection } from '@app/constants/ApiTypes/requests'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'

export const getFaqDescriptor = new ApiActionBuilderDescriptor().setLabel('getFaq').setShape<ApiDataResponse<ApiFaq>>()

export const getFaqBySectionDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('getFaqBySectionLanding')
  .setShape<ApiDataResponse<ApiFaq>, { section: FaqTaskSection }>()

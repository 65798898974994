import { useSelector } from 'react-redux'

import { ResultSelector } from './SelectorResult'

export const useSelectorResult = <T>(selector: ResultSelector<T>) => {
  return useSelector(selector)
}

export const useSelectorResultValue = <T>(selector: ResultSelector<T>) => {
  const val = useSelector(selector)
  if (val.error) throw val.value
  return val.value
}

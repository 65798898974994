import { ThunkAction } from '@app/store/thunk'

export class ActionRequiredError extends Error {
  action!: ThunkAction<Promise<void>>

  private constructor(message: string) {
    super(message)
  }

  static create(message: string, action: ThunkAction<Promise<void>>) {
    const err = new ActionRequiredError(message)
    err.action = action
    return err
  }
}

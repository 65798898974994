import {} from '@app/utils/normalizer'

import { ApiLocation } from '@app/constants/ApiTypes/entities'

import { apiNormalize } from '@app/utils/apiNormalizer'
import { matchReduxAction } from '@app/utils/matchReduxAction'
import { mergeMaps } from '@app/utils/mergeMaps'

import { getAnnouncementsSearchMapDescriptor } from '@app/store/actions/api/announcements.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  [key: string]: ApiLocation
}>({}, builder => {
  builder.setDefaultHandler((state, action) => {
    if (matchReduxAction(action, getAnnouncementsSearchMapDescriptor.shapes.fulfilled)) return state
    const { locations } = apiNormalize(action)
    if (!locations) return state

    return mergeMaps(state, locations)
  })
})

import { TaskState } from '@app/types/tasks'

import moment from '@app/utils/moment'

import { withAbortSignal } from '@app/packages/abortContext/actions'
import { Task, TaskValue, TaskWorker } from '@app/packages/task/Task'

import { ApiActionPromise } from '@app/store/apiMiddleware/types'
import { StoreDispatch } from '@app/store/dispatch'
import { createThunk, ThunkAction } from '@app/store/thunk'
import { createAction } from '@app/store/toolkit'

export const setTask = createAction<'SET_TASK', { label: string; task: Task<any, any> }>('SET_TASK')

export const createTask = <S, W extends TaskWorker>(s: string, taskCreator: (dispatch: StoreDispatch) => Task<S, W>) =>
  createThunk((dispatch, getState) => {
    const task = getState().tasks[s]
    if (task instanceof Task) return task as Task<S, W>
    if (isTaskState(task)) {
      const newTask = taskCreator(dispatch)
      newTask.state.update(() => task.state)
      newTask.value.update(() => task.value)
      newTask.loadedAt.update(() => (task.loadedAt ? moment(task.loadedAt) : null))
      dispatch(setTask({ label: s, task: newTask }))
      return newTask
    }
    const newTask = taskCreator(dispatch)
    dispatch(setTask({ label: s, task: newTask }))
    return newTask
  })

export function withTaskContext<T>(ctx: { abortController: AbortController }, p: ThunkAction<ApiActionPromise<T>>) {
  return createThunk(dispatch => {
    return dispatch(withAbortSignal(ctx.abortController.signal, p)).then(resp => {
      if (resp?.error) throw resp.payload
      return resp?.payload
    })
  })
}

export async function updateTaskValue<T extends Task<any, () => Promise<any>>>(task: T, freshnessInMinutes?: number): Promise<TaskValue<T>> {
  if (task.loading.value) return await task.currentPromise.value
  if (!task.loadedAt.value) return await task.call()
  if (freshnessInMinutes && moment().diff(task.loadedAt.value, 'minutes') < freshnessInMinutes) return task.value.value
  return await task.call()
}

const isTaskState = (t: unknown): t is TaskState => {
  return !!(t && typeof t === 'object' && 'isTaskState' in t && t.isTaskState === true)
}

import type {
  ApiAffiliate,
  ApiCountry,
  ApiLink,
  ApiLocality,
  ApiPlace,
  ApiPlaygroundEventType,
  ApiRegion,
  ApiSchool,
  ApiStatistics,
} from '@app/constants/ApiTypes/entities'
import { ApiDataResponse } from '@app/constants/ApiTypes/misc'
import type { Locale } from '@app/constants/Locales'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'
import { createAction } from '@app/store/toolkit'
import type { ConfigState } from '@app/store/types/config'

export const getAffiliatesDescriptor = new ApiActionBuilderDescriptor().setLabel('GET_AFFILIATES').setShape<{ data: ApiAffiliate[] }>()

export const getPlaygroundRequestsEventTypesDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('GET_PLAYGROUND_REQUESTS_EVENT_TYPES')
  .setShape<{ data: ApiPlaygroundEventType[] }>()

export const getRegionsDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('GET_REGIONS')
  .setShape<{ data: ApiRegion[]; included: (ApiPlace | ApiCountry | ApiLocality)[] }>()

export const getRegionDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('GET_REGION')
  .setShape<{ data: ApiRegion; included: (ApiPlace | ApiCountry | ApiLocality)[] }>()

export const getSchoolsDescriptor = new ApiActionBuilderDescriptor().setLabel('GET_SCHOOLS').setShape<ApiDataResponse<ApiSchool[]>>()

export const getStatisticsDescriptor = new ApiActionBuilderDescriptor().setLabel('GET_STATISTICS').setShape<{ data: ApiStatistics }>()

export const getLinksDescriptor = new ApiActionBuilderDescriptor().setLabel('GET_LINKS').setShape<{ data: ApiLink[] }>()
export const passData = createAction<'PASS_DATA', any>('PASS_DATA')
export const setLocaleAction = createAction<'MISC_SET_LOCALE', { locale: Locale }>('MISC_SET_LOCALE')
export const setTestFlight = createAction<'MISC_SET_TEST_FLIGHT', Partial<ConfigState['testFlights']>>('MISC_SET_TEST_FLIGHT')

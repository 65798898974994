import { ApiFeature } from '@app/constants/ApiTypes/entities'

import { getFeaturesDescriptor } from '@app/store/actions/api/features.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<ApiFeature[] | null>(null, builder => {
  builder.addCases([getFeaturesDescriptor.shapes.fulfilled], (_state, action) => {
    return action.payload.data
  })
})

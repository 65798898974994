import { ApiContact, ApiRegion } from '@app/constants/ApiTypes/entities'
import { DEFAULT_SCHOOL_PHONE, SUPPORT_OFFICE_HOURS } from '@app/constants/Misc'

import { assertNever } from '@app/utils/assertNever'
import { createSortHandler } from '@app/utils/createSortHandler'

import { ActionRequiredError } from '@app/packages/selectorResult/ActionRequiredError'
import { createSelectorResult } from '@app/packages/selectorResult/createSelectorResult'

import { getContactsTask } from '@app/store/actions/contacts'

import { profileRegionIdSelector } from './profile'
import { regionsModelsSelector } from './regions'

export const contactsModelsSelector = createSelectorResult([state => state.contacts], contacts => {
  if (!contacts) {
    throw ActionRequiredError.create('Contacts must be fetched', async dispatch => {
      await dispatch(getContactsTask).callLoosely()
    })
  }
  return contacts
})
export const contactsListSelector = createSelectorResult([contactsModelsSelector, regionsModelsSelector], (models, regions) => {
  const contacts = Object.values(models)
    .map(c => {
      const regionId = c.relationships.region.data?.id
      const region = regionId ? regions[regionId] : null
      if (regionId && !region) throw new Error("Can't find region")

      return {
        id: c.id,
        attributes: c.attributes,
        region,
      }
    })
    .sort(createSortHandler(a => [a.region?.id ? getRegionWeight(a.region) : -1, a.region?.id ? -parseFloat(a.region.id) : 1, -parseFloat(a.id)]))

  return contacts
})

export const contactsSchoolsSelector = createSelectorResult([contactsListSelector], contacts =>
  contacts.reduce<Partial<Record<string, ApiContact['attributes']>>>((acc, c) => {
    if (c.attributes.department === 'school' && !!c.region) {
      acc[c.region.id] = c.attributes
    }
    return acc
  }, {})
)

export const contactsAcademySelector = createSelectorResult([contactsListSelector], contacts => contacts.find(c => c.attributes.department === 'academy'))

export const createContactDataSelector = (area: 'main' | 'sitter' | 'school') =>
  createSelectorResult([profileRegionIdSelector, contactsListSelector], (regionId, contacts) => {
    const phone = (() => {
      const defaultPhone = contacts.find(c => c.attributes.department === 'support')?.attributes.phone ?? DEFAULT_SCHOOL_PHONE
      switch (area) {
        case 'main':
          return defaultPhone
        case 'sitter':
          return defaultPhone
        case 'school':
          return (
            (
              contacts.find(c => c.attributes.department === 'school' && c.region && c.region.id === regionId && c.attributes.phone) ??
              contacts.find(c => c.attributes.department === 'school' && !c.region && c.attributes.phone)
            )?.attributes.phone ?? defaultPhone
          )
        default:
          return assertNever(area)
      }
    })()

    return { phone, officeHours: SUPPORT_OFFICE_HOURS }
  })

function getRegionWeight(region: ApiRegion) {
  return REGION_WEIGHTS.indexOf(region.attributes.type) + 1
}

const REGION_WEIGHTS = ['world', 'country', 'city']

import React, { useMemo } from 'react'
import { unreadCountSelector } from '@kidsout-kidsout/chat/lib/selectors'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import { TEST_USERS } from '@app/constants/TestUsers'

import { pathIsSubPathOf } from '@app/utils/url'

import { useCreateSelector } from '@app/hooks/useCreateSelector'

import { affiliatesEnabledSelector, configSelector } from '@app/store/selectors/misc'
import { newsUnreadCountSelector } from '@app/store/selectors/news'
import { profileUserSelector } from '@app/store/selectors/profile'
import { sessionSelector } from '@app/store/selectors/session'
import { createUserURLSelector } from '@app/store/selectors/user'

import { useHeaderMenu } from '@app/routes/Academy/useHeaderMenu'

import { intlFormatters, NavItemVisibility, NavMenuChild, NavMenuItem } from './navItemsUtils'

export const useNavItems = (pathname: string, type: 'header' | 'side') => {
  const { formatMessage } = useIntl()
  const academyMenu = useHeaderMenu('academy', type)
  const newYearPartyMenu = useHeaderMenu('new_year_party', type)
  const user = useSelector(profileUserSelector)
  const config = useSelector(configSelector)
  const session = useSelector(sessionSelector)
  const messagesUnreadCount = useSelector(unreadCountSelector)
  const newsUnreadCount = useSelector(newsUnreadCountSelector)
  const affiliatesEnabled = useSelector(affiliatesEnabledSelector)

  const isRemovable =
    config.accountManagement && !session.supervise && session.access_token && !TEST_USERS.find(u => u.id === user?.id || u.token === session.access_token)
  const isSupervised = session.supervise
  const isVisitor = !user || user.account_type === 'visitor'

  const sitterUrl = useCreateSelector(() => (user?.id ? createUserURLSelector(user.id) : () => ''), [user?.id])

  const settingsItems = useMemo(() => {
    const settingsItem = ((): NavMenuItem | null => {
      if (!user || user.account_type === 'visitor') return null

      const adminItems: NavMenuChild[] = []
      if (isRemovable) adminItems.push({ type: 'user-destroy', id: 'user-destroy', name: user.name || user.phone || '{name}' })
      if (isSupervised) adminItems.push({ type: 'user-desupervise', id: 'user-desupervise', name: user.name || user.phone || '{name}' })

      if (user.account_type === 'parent')
        return {
          type: 'item',
          id: 'settings',
          icon: 'percent',
          url: '/settings',
          subtype: 'settings',
          title: <FormattedMessage {...messages.main_settings} values={intlFormatters} />,
          children: user.registration_completed
            ? [
                {
                  type: 'item',
                  id: 'settings',
                  url: '/settings',
                  title: formatMessage(messages.settings),
                },
                {
                  type: 'item',
                  id: 'reviews',
                  url: '/reviews',
                  title: formatMessage(messages.reviews),
                },
                {
                  type: 'item',
                  id: 'billing',
                  url: '/billing',
                  title: formatMessage(messages.billing),
                },
                {
                  type: 'item',
                  id: 'search_promocode',
                  url: '/promocode',
                  title: formatMessage(messages.promocode),
                },
                {
                  type: 'item',
                  id: 'search_free_subscription',
                  url: '/referrals',
                  title: formatMessage(messages.free_subscription),
                },
                {
                  type: 'item',
                  id: 'notification_settings',
                  url: '/notification-settings',
                  title: formatMessage(messages.notifications),
                },
                ...adminItems,
                {
                  type: 'signout',
                  id: 'signout',
                },
              ]
            : [
                {
                  type: 'item',
                  id: 'settings',
                  url: '/settings',
                  title: formatMessage(messages.settings),
                },
                ...adminItems,
                {
                  type: 'signout',
                  id: 'signout',
                },
              ],
        }

      if (user.account_type === 'sitter') {
        if (!user.approved && !user.training_completed)
          return {
            type: 'item',
            id: 'settings',
            icon: 'percent',
            url: '/',
            subtype: 'settings',
            title: <FormattedMessage {...messages.main_settings} values={intlFormatters} />,
            children: [
              {
                type: 'item',
                id: 'registration',
                url: '/registration',
                title: formatMessage(messages.registration),
              },
              ...adminItems,
              {
                type: 'signout',
                id: 'signout',
              },
            ],
          }

        return {
          type: 'item',
          id: 'settings',
          icon: 'percent',
          url: '/settings',
          subtype: 'settings',
          title: <FormattedMessage {...messages.main_settings} values={intlFormatters} />,
          children:
            user.approved || user.training_completed
              ? [
                  {
                    type: 'item',
                    id: 'settings',
                    url: '/settings',
                    title: formatMessage(messages.settings),
                  },
                  {
                    type: 'item',
                    id: 'profile',
                    url: sitterUrl,
                    title: formatMessage(messages.sitter_profile),
                  },
                  {
                    type: 'item',
                    id: 'schedule',
                    url: '/schedule',
                    title: formatMessage(messages.schedule),
                  },
                  {
                    type: 'item',
                    id: 'search_free_subscription',
                    url: '/referrals',
                    title: formatMessage(messages.bonuses),
                  },
                  {
                    type: 'item',
                    id: 'notification_settings',
                    url: '/notification-settings',
                    title: formatMessage(messages.notifications),
                  },
                  ...adminItems,
                  {
                    type: 'signout',
                    id: 'signout',
                  },
                ]
              : [
                  {
                    type: 'item',
                    id: 'settings',
                    url: '/settings',
                    title: formatMessage(messages.settings),
                  },
                  {
                    type: 'item',
                    id: 'schedule',
                    url: '/schedule',
                    title: formatMessage(messages.schedule),
                  },
                  ...adminItems,
                  {
                    type: 'signout',
                    id: 'signout',
                  },
                ],
        }
      }

      return null
    })()

    const settingsItems = settingsItem ? [settingsItem] : []
    return settingsItems
  }, [user, isRemovable, isSupervised, formatMessage, sitterUrl])

  const sitterItems = useMemo<NavMenuItem[]>(
    () =>
      isVisitor
        ? [
            { type: 'expander', id: 'prebabysitter-spacer' },
            {
              type: 'item',
              id: 'babysitter',
              icon: 'profile',
              url: '/babysitter',
              region: true,
              title: <FormattedMessage {...messages.main_babysitter_link} values={intlFormatters} />,
              visibility: NavItemVisibility.Side | NavItemVisibility.Footer,
              children: [
                {
                  type: 'item',
                  id: 'babysitter_main',
                  url: '/babysitter',
                  region: true,
                  title: formatMessage(messages.become_babysitter),
                },
                {
                  type: 'item',
                  id: 'babysitter_find_orders',
                  url: '/announcements',
                  region: false,
                  title: formatMessage(messages.find_orders),
                },
                {
                  type: 'item',
                  id: 'babysitter_school',
                  url: '/babysitter/school',
                  region: true,
                  title: formatMessage(messages.babysitter_school),
                },
              ],
            },
          ]
        : [],
    [formatMessage, isVisitor]
  )

  const helpItem = useMemo<NavMenuItem>(
    () => ({
      type: 'item',
      id: 'help',
      icon: 'phone',
      url: '/contacts',
      title: <FormattedMessage {...messages.main_help} values={intlFormatters} />,
      children: [
        {
          type: 'item',
          id: 'help_contacts',
          url: '/contacts',
          title: formatMessage(messages.contacts),
        },
        {
          type: 'item',
          id: 'help_faq',
          url: '/faq',
          title: formatMessage(messages.faq),
        },
        {
          type: 'item',
          id: 'help_how_it_works',
          url: '/how-it-works',
          title: formatMessage(messages.how_it_works),
        },
        {
          type: 'item',
          id: 'help_insurance',
          url: '/insurance',
          title: formatMessage(messages.insurance),
        },
      ],
    }),
    [formatMessage]
  )

  return useMemo((): NavMenuItem[] => {
    if (pathIsSubPathOf('/babysitter', pathname) || (pathname === '/announcements' && (!user || user.account_type === 'visitor'))) {
      const items: NavMenuItem[] = [
        {
          type: 'item',
          id: 'babysitter_main_item',
          icon: 'profile',
          url: '/babysitter',
          region: true,
          title: <FormattedMessage {...messages.main_become_babysitter} values={intlFormatters} />,
        },
        {
          type: 'item',
          id: 'babysitter_find_orders_main_item',
          icon: 'magnifier',
          url: '/announcements',
          region: false,
          title: <FormattedMessage {...messages.main_find_orders} values={intlFormatters} />,
        },
        {
          type: 'item',
          id: 'babysitter_school_main_item',
          icon: 'school-outlined',
          url: '/babysitter/school',
          region: true,
          title: <FormattedMessage {...messages.main_babysitter_school} values={intlFormatters} />,
        },
        {
          type: 'item',
          id: 'blog',
          icon: 'pen',
          external: true,
          url: 'https://blog.kidsout.ru',
          title: <FormattedMessage {...messages.main_blog} values={intlFormatters} />,
        },
        {
          type: 'item',
          id: 'help',
          icon: 'phone',
          url: '/contacts',
          title: <FormattedMessage {...messages.main_help} values={intlFormatters} />,
        },
        ...settingsItems,
      ]

      return items
    }

    if (!user || user.account_type === 'visitor')
      return [
        {
          type: 'item',
          id: 'search',
          icon: 'magnifier',
          url: '/search',
          title: <FormattedMessage {...messages.main_search} values={intlFormatters} />,
        },
        academyMenu,
        {
          type: 'item',
          id: 'giftcard',
          icon: 'gift',
          url: '/giftcard',
          region: true,
          matches: (path: string) => path.startsWith('/giftcard'),
          title: <FormattedMessage {...messages.main_giftcard} values={intlFormatters} />,
        },
        {
          type: 'item',
          id: 'playground',
          icon: 'playground',
          url: '/playground',
          region: true,
          visibility: NavItemVisibility.Footer | NavItemVisibility.Side,
          title: <FormattedMessage {...messages.main_playground} values={intlFormatters} />,
        },
        newYearPartyMenu,
        {
          type: 'item',
          id: 'affiliate',
          icon: 'percent',
          url: '/affiliate',
          visibility: affiliatesEnabled ? NavItemVisibility.All : NavItemVisibility.None,
          title: <FormattedMessage {...messages.main_affiliate} values={intlFormatters} />,
        },
        {
          type: 'item',
          id: 'blog',
          icon: 'pen',
          external: true,
          url: 'https://blog.kidsout.ru',
          title: <FormattedMessage {...messages.main_blog} values={intlFormatters} />,
        },
        helpItem,
        ...sitterItems,
      ]

    if (user.account_type === 'parent')
      return [
        {
          type: 'item',
          id: 'search',
          icon: 'magnifier',
          url: '/',
          matches: pathname => pathname === '/' || pathname.startsWith('/m/'),
          title: <FormattedMessage {...messages.main_search} values={intlFormatters} />,
          children: [
            {
              type: 'item',
              id: 'search_messages',
              url: '/',
              matches: pathname => pathname === '/' || pathname.startsWith('/m/'),
              title: formatMessage(messages.messages),
              count: messagesUnreadCount,
            },
            {
              type: 'item',
              id: 'search_new_announcement',
              url: '/announcements/new',
              title: formatMessage(messages.new_announcement),
            },
            {
              type: 'item',
              id: 'search_main',
              url: '/search',
              title: formatMessage(messages.sitter_search),
            },
            {
              type: 'item',
              id: 'search_parent_announcements',
              url: '/announcements',
              matches: path => pathIsSubPathOf('/announcements', path),
              title: formatMessage(messages.parent_announcements),
            },
            {
              type: 'item',
              id: 'history',
              url: '/history',
              title: formatMessage(messages.history),
            },
            {
              type: 'item',
              id: 'favorites',
              url: '/favorites',
              title: formatMessage(messages.favorites),
            },
            {
              type: 'item',
              id: 'trusted',
              url: '/trusted',
              title: formatMessage(messages.trusted),
            },
          ],
        },
        academyMenu,
        {
          type: 'item',
          id: 'news',
          icon: 'scroll',
          url: '/news',
          count: newsUnreadCount,
          title: <FormattedMessage {...messages.main_news} values={intlFormatters} />,
        },
        {
          type: 'item',
          id: 'giftcard',
          icon: 'gift',
          url: '/giftcard',
          matches: path => pathIsSubPathOf('/giftcard', path),
          title: <FormattedMessage {...messages.main_giftcard} values={intlFormatters} />,
        },
        {
          type: 'item',
          id: 'playground',
          icon: 'playground',
          url: '/playground',
          visibility: NavItemVisibility.Footer | NavItemVisibility.Side,
          title: <FormattedMessage {...messages.main_playground} values={intlFormatters} />,
        },
        newYearPartyMenu,
        {
          type: 'item',
          id: 'affiliate',
          icon: 'percent',
          url: '/affiliate',
          visibility: affiliatesEnabled ? NavItemVisibility.All : NavItemVisibility.None,
          title: <FormattedMessage {...messages.main_affiliate} values={intlFormatters} />,
        },
        {
          type: 'item',
          id: 'blog',
          icon: 'pen',
          external: true,
          url: 'https://blog.kidsout.ru',
          title: <FormattedMessage {...messages.main_blog} values={intlFormatters} />,
        },
        helpItem,
        ...sitterItems,
        ...settingsItems,
      ]

    if (!user.approved && !user.training_completed) return [...sitterItems, ...settingsItems]

    return [
      {
        type: 'item',
        id: 'search',
        icon: 'magnifier',
        url: '/',
        matches: path => path === '/' || pathIsSubPathOf('/announcements', path) || pathIsSubPathOf('/m', path),
        title: <FormattedMessage {...messages.main_search_sitter} values={intlFormatters} />,
        children: [
          {
            type: 'item',
            id: 'search_sitter_announcements',
            url: '/',
            matches: path => path === '/' || pathIsSubPathOf('/announcements', path),
            title: formatMessage(messages.sitter_announcements),
          },
          {
            type: 'item',
            id: 'search_messages',
            url: '/m',
            matches: path => pathIsSubPathOf('/m', path),
            title: formatMessage(messages.messages),
            count: messagesUnreadCount,
          },
          {
            type: 'item',
            id: 'history',
            url: '/history',
            title: formatMessage(messages.history),
          },
          {
            type: 'item',
            id: 'favorites',
            url: '/favorites',
            title: formatMessage(messages.favorites),
          },
          {
            type: 'item',
            id: 'ignored',
            url: '/ignored',
            title: formatMessage(messages.excluded),
          },
          {
            type: 'item',
            id: 'trusting',
            url: '/trusting',
            title: formatMessage(messages.trusted),
          },
        ],
      },
      {
        type: 'item',
        id: 'rules',
        icon: 'book',
        url: '/rules',
        title: <FormattedMessage {...messages.main_rules} values={intlFormatters} />,
        children: [
          {
            type: 'item',
            id: 'rules',
            url: '/rules',
            title: formatMessage(messages.sitter_rules),
          },
          {
            type: 'item',
            id: 'sitter_profile',
            url: '/sitter-profile',
            title: formatMessage(messages.sitter_profile_rules),
          },
        ],
      },
      {
        type: 'item',
        id: 'news',
        icon: 'scroll',
        url: '/news',
        count: newsUnreadCount,
        title: <FormattedMessage {...messages.main_news} values={intlFormatters} />,
      },
      {
        type: 'item',
        id: 'affiliate_sitter',
        icon: 'percent',
        url: '/affiliate',
        visibility: affiliatesEnabled ? NavItemVisibility.Footer | NavItemVisibility.Side : NavItemVisibility.None,
        title: <FormattedMessage {...messages.main_affiliate_sitter} values={intlFormatters} />,
      },
      {
        type: 'item',
        id: 'blog',
        icon: 'pen',
        external: true,
        url: 'https://blog.kidsout.ru',
        title: <FormattedMessage {...messages.main_blog} values={intlFormatters} />,
      },
      helpItem,
      ...sitterItems,
      ...settingsItems,
    ]
  }, [
    pathname,
    user,
    academyMenu,
    newYearPartyMenu,
    affiliatesEnabled,
    helpItem,
    sitterItems,
    formatMessage,
    messagesUnreadCount,
    newsUnreadCount,
    settingsItems,
  ])
}

const messages = defineMessages({
  main_settings: 'Личный{whitespace}кабинет',
  main_search: 'Найти{whitespace}бебиситтера',
  main_search_sitter: 'Работа{whitespace}и общение',
  main_quiz: 'Kidsout{whitespace}Quiz',
  main_giftcard: 'Подарочная{whitespace}карта',
  main_blog: 'Блог',
  main_playground: 'Детские{whitespace}площадки',
  main_affiliate: 'Партнерские{whitespace}программы',
  main_affiliate_sitter: 'Скидки{whitespace}для\u00a0ситтеров',
  main_babysitter_link: 'Беби{dashspace}ситтерам',
  main_become_babysitter: 'Стать{whitespace}бебиситтером',
  main_find_orders: 'Найти{whitespace}заказы',
  main_babysitter_school: 'Школа{whitespace}бебиситтеров',
  main_news: 'Новости{whitespace}сервиса',
  main_rules: 'Правила{whitespace}работы',
  main_new_year_2021: 'Новогодний{whitespace}Kidsout',
  main_help: 'Справка',

  parent_announcements: 'Мои объявления',
  sitter_announcements: 'Объявления родителей',
  new_announcement: 'Найти ситтера',
  messages: 'Сообщения',
  sitter_search: 'Наши ситтеры',
  promocode: 'Промокод',
  free_subscription: 'Бесплатные подписки',
  bonuses: 'Бонусы',

  become_babysitter: 'Стать бебиситтером',
  find_orders: 'Найти заказы',
  babysitter_school: 'Школа бебиситтеров',

  contacts: 'Контакты',
  faq: 'Вопросы и\u00a0ответы',
  how_it_works: 'Как это работает',
  insurance: 'Страхование',

  sitter_rules: 'Правила взаимодействия',
  sitter_profile_rules: 'Правила заполнения анкеты',

  registration: 'Регистрация',
  settings: 'Настройки',
  notifications: 'Настройки уведомлений',
  favorites: 'Избранные',
  excluded: 'Исключенные',
  trusted: 'Постоянные',
  history: 'История заказов',
  reviews: 'Отзывы',
  billing: 'Оплата',
  sitter_profile: 'Анкета и\u00a0отзывы',
  schedule: 'Расписание',
})

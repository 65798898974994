import queryString from 'query-string'

import { ServerState } from '@app/constants/StoreTypes'

import { ApplicationAgent } from '@app/services/ApplicationAgent'

export function headers(extraHeaders?: { [key: string]: string }) {
  return ({
    session,
    locale,
    server,
    config,
  }: {
    session: { access_token: string | null; uuid: string }
    locale: string
    server: ServerState | null
    config: { appRequestId: string }
  }) => {
    return Object.assign(
      {
        'content-type': 'application/json',
        'X-Kidsout-Session-Id': session.uuid,
        'X-Kidsout-App-Request-Id': config.appRequestId,
        'accept-language': locale,
        'Application-Agent': ApplicationAgent.shared.agent,
      },
      server ? { 'x-forwarded-for': server.ips.length ? server.ips.join(', ') : server.ip } : null,
      session.access_token ? { Authorization: `Bearer ${session.access_token}` } : null,
      extraHeaders
    )
  }
}

export function path(pathname: string, query?: { [key: string]: any }) {
  const fullPath = pathname + (query ? `?${queryString.stringify(query, { arrayFormat: 'bracket' })}` : '')

  return function (state: { config: { proxy: boolean; port: string | number; apiUrl: string } }) {
    // // test prod
    // if (Math.random() < 2) {
    //   return `https://api.kidsout.ru${fullPath}`
    // }

    if (state.config.proxy) {
      if (IS_BROWSER) {
        return fullPath
      } else if (state.config.proxy) {
        return `http://localhost:${state.config.port}${fullPath}`
      }
    }
    return `${state.config.apiUrl}${fullPath}`
  }
}

export function internal(pathname: string, query?: { [key: string]: any }) {
  const fullPath = '/internal' + pathname + (query ? `?${queryString.stringify(query, { arrayFormat: 'bracket' })}` : '')

  return function (state: { config: { proxy: boolean; port: string | number; apiUrl: string } }) {
    if (IS_BROWSER) return fullPath
    return `http://localhost:${state.config.port}${fullPath}`
  }
}

import { AbortError, isAbortError } from '@app/errors/AbortError'

import { asError } from '@app/utils/asError'
import moment, { Moment } from '@app/utils/moment'

import { ComputedValue } from '@app/packages/reactives/ComputedValue'
import { ReactiveValue } from '@app/packages/reactives/ReactiveValue'
import { TrackableValue } from '@app/packages/reactives/TrackableValue'

export type TaskContext<S> = {
  abortController: AbortController
  state: ReactiveValue<S>
}

export type TaskWorker = (...args: any[]) => Promise<any>
export type TaskCreator<S, L extends TaskWorker> = (ctx: TaskContext<S>) => L

export class Task<S, L extends TaskWorker> {
  readonly value = new ReactiveValue<Awaited<ReturnType<L>> | undefined>(undefined)
  readonly error = new ReactiveValue<Error | null>(null)
  readonly currentPromise = new ReactiveValue<ReturnType<L> | null>(null)
  readonly loading: TrackableValue<boolean>
  readonly loadedAt = new ReactiveValue<Moment | null>(null)
  readonly state: ReactiveValue<S>

  private abortController: AbortController | undefined = undefined
  private taskCreator: TaskCreator<S, L>
  private getInitialState: () => S

  private constructor(getInitialState: () => S, taskCreator: TaskCreator<S, L>) {
    this.getInitialState = getInitialState
    this.state = new ReactiveValue(getInitialState())
    this.taskCreator = taskCreator
    this.loading = new ComputedValue([this.currentPromise], currentPromise => !!currentPromise)
  }

  static create<L extends TaskWorker>(taskCreator: TaskCreator<undefined, L>, getInitialState?: undefined): Task<undefined, L>

  static create<S, L extends TaskWorker>(taskCreator: TaskCreator<S, L>, getInitialState: () => S): Task<S, L>

  static create<S, L extends TaskWorker>(taskCreator: TaskCreator<S, L>, getInitialState?: () => S): Task<S, L> {
    return new Task<S, L>(getInitialState || ((() => undefined) as any), taskCreator)
  }

  call(...args: Parameters<L>): Promise<Awaited<ReturnType<L>>> {
    this.abort()
    const abortController = new AbortController()
    this.abortController = abortController
    this.error.update(() => null)
    const ctx: TaskContext<S> = { abortController, state: this.state }
    const promise = this.taskCreator(ctx)(...args)
      .then(value => {
        if (abortController.signal.aborted) throw new AbortError('Task was aborted')
        this.value.update(() => value)
        this.loadedAt.update(() => moment())
        this.error.update(() => null)
        return value
      })
      .catch(e => {
        if (isAbortError(e)) throw e
        if (abortController.signal.aborted) throw new AbortError('Task was aborted')
        this.error.update(() => asError(e))
        throw e
      })
      .finally(() => {
        abortController.abort()
        this.currentPromise.update(c => (promise === c ? null : c))
      })
    this.currentPromise.update(() => promise as any)
    return promise
  }

  callSilent(...args: Parameters<L>): Promise<Awaited<ReturnType<L> | undefined>> {
    return this.call(...args).catch(() => undefined)
  }

  callLoosely(...args: Parameters<L>) {
    if (this.currentPromise.value) return this.currentPromise.value
    return this.call(...args)
  }

  abort(): void {
    this.abortController?.abort()
    this.abortController = undefined
    this.currentPromise.update(() => null)
  }

  reset() {
    this.abort()
    this.state.update(() => this.getInitialState())
    this.value.update(() => undefined)
    this.error.update(() => null)
    this.currentPromise.update(() => null)
    this.loadedAt.update(() => null)
  }

  resetState() {
    this.state.update(() => this.getInitialState())
  }
}

export type TaskValue<T extends Task<any, () => Promise<any>>> = T extends Task<any, () => Promise<infer V>> ? V : never

import { ApiContact } from '@app/constants/ApiTypes/entities'

import { normalize } from '@app/utils/normalizer'

import { getContactsDescriptor } from '@app/store/actions/api/contacts.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{ [key: string]: ApiContact } | null>(null, builder => {
  builder.addCase(getContactsDescriptor.shapes.fulfilled, (state, action) => {
    const { contacts } = normalize(action.payload)
    if (!contacts) return state

    return { ...state, ...contacts }
  })
})

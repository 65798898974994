import { ComponentType } from 'react'
import { createSelector } from 'reselect'

import { IMPORT_MAP } from '@app/importMap'

import { SITTER_SCREENING_ENABLED } from '@app/constants/Misc'
import { type SearchParams } from '@app/constants/StoreTypes'

import { makeLoginRedirect } from '@app/utils/getShouldLoginRedirect'
import moment from '@app/utils/moment'
import { awaitAsyncRoutes, makeRoutePath, matchRoutes } from '@app/utils/routing/functions'
import { AsyncRoute, LocationDescriptorObject, Route } from '@app/utils/routing/types'

import { affiliatesEnabledSelector } from '@app/store/selectors/misc'
import { availableRegionsSlugsSelector, availableSlugsSelector } from '@app/store/selectors/regions'
import { Store } from '@app/store/store'

import { createStaticPageRenderer } from '@app/routes/Static/components/StaticPageRenderer'
import { navManagerSelector } from '@app/routes/Static/staticMenu'

import Root from '@app/components/Root'

import { isCandidateSitterSelector, userDataSelector } from './selectors'

export default async (location: LocationDescriptorObject, store: Store): Promise<Route[]> => [
  {
    component: Root,
    routes: await (async () => {
      const state = store.getState()
      const asyncRoutes = routesSelector(state)
      const routes = asyncRoutes
      const regions = availableRegionsSlugsSelector(state)

      if (!location.pathname) return []

      const matched = matchRoutes(regions, routes, location.pathname)

      const path = makeRoutePath(matched)

      if (!path) return []

      return await awaitAsyncRoutes([path], location)
    })(),
  },
]

export function makeRedirectRoute(newPath: string, label: string): AsyncRoute {
  return {
    event_id: `${label}_redirect`,
    component: () =>
      IMPORT_MAP.components.Redirect().then(m =>
        m.createRedirect(
          location => ({
            pathname: newPath,
            search: location.search,
            hash: location.hash,
          }),
          false
        )
      ),
  }
}

const createRedirect =
  (path: string, permanent = false) =>
  () =>
    IMPORT_MAP.components.Redirect().then(m => m.createRedirect(path, permanent))
const RedirectToRoot = createRedirect('/')
const RedirectToSchool = createRedirect('/babysitter/school', true)
const LandingParent = () => IMPORT_MAP.routes.Static.LandingParent().then(m => m.LandingParent)
const Announcements = () => IMPORT_MAP.routes.Announcements.SitterAnnouncements().then(m => m.default)
const MessagesLayout = () => IMPORT_MAP.routes.Messages.MessagesLayout().then(m => m.default)
const ParentProfile = () => import('@app/routes/Profile/Parent').then(module => module.Parent)
const SitterProfile = () => import('@app/routes/Profile/Sitter').then(module => module.Sitter)
const ParentAnnouncements = () => IMPORT_MAP.routes.Announcements.ParentAnnouncements().then(m => m.ParentAnnouncements)
const Announcement = () => IMPORT_MAP.routes.Announcements.Announcement().then(m => m.Announcement)
const DepersonalizedAnnouncements = () => IMPORT_MAP.routes.Announcements.SitterAnnouncements().then(m => m.default)
const PublicAnnouncement = () => import('@app/routes/Announcements/PublicAnnouncement').then(m => m.PublicAnnouncement)
const NewAnnouncement = () => IMPORT_MAP.routes.Announcements.NewAnnouncement().then(m => m.default)
const EditAnnouncement = () => IMPORT_MAP.routes.Announcements.EditAnnouncement().then(m => m.EditAnnouncement)
const Apps = () => IMPORT_MAP.routes.Apps.Apps().then(m => m.Apps)
const ApkLink = () => IMPORT_MAP.routes.Apps.ApkLink().then(m => m.ApkLink)

const routesSelector = createSelector(
  [userDataSelector, isCandidateSitterSelector, availableSlugsSelector, affiliatesEnabledSelector, navManagerSelector],
  (user, isCandidateSitter, regionSlugs, affiliatesEnabled, navManager) => {
    const manager = new RouteManager()

    const checkAccountType = (accountType: 'parent' | 'sitter') => {
      if (!user || user.account_type !== accountType) return NO_ROUTE
      return null
    }

    const checkIsCandidateSitter = (): AsyncRoute | null => {
      if (isCandidateSitter)
        return {
          event_id: 'redirect_to_registration',
          component: createRedirect('/registration'),
        }
      return null
    }

    const checkRegistrationIncomplete = (accountType?: 'parent' | 'sitter'): AsyncRoute | null => {
      if (user && (accountType ? user.account_type === accountType : true) && !user.registration_completed) {
        return {
          event_id: 'redirect_to_settings',
          component: createRedirect('/settings'),
        }
      }
      return null
    }

    const checkMissingAuth = (id: string, params?: AsyncRoute['event_params']): AsyncRoute | null => {
      if (!user) {
        return {
          event_id: id,
          event_params: params,
          component: async location => makeLoginRedirect(`${location.pathname || '/'}${location.search || ''}${location.hash || ''}`),
        }
      }
      return null
    }

    manager.add(
      '/',
      checkIsCandidateSitter() ??
        checkRegistrationIncomplete() ??
        (user?.account_type === 'sitter'
          ? {
              event_id: 'announcements',
              component: Announcements,
            }
          : user?.account_type === 'parent'
            ? {
                event_id: 'conversations',
                component: MessagesLayout,
              }
            : {
                event_id: 'main_page',
                component: LandingParent,
                regional: true,
              })
    )

    manager.add('/search', {
      name: 'search',
      event_id: 'search',
      event_data: async (location, dispatch) => {
        const ignoredKeys: (keyof SearchParams)[] = ['address']
        const { SearchParamsManager } = await import('@app/routes/Search/utils/SearchParamsManager')
        const manager = SearchParamsManager.fromSearchString(location.search, dispatch, moment())
        const params = manager.effectiveParams
        return Object.keys(params).reduce<Record<string, any>>((c, key) => {
          if (!ignoredKeys.includes(key as any)) c[key] = params[key]
          return c
        }, {})
      },
      component: () => IMPORT_MAP.routes.Search.Search().then(m => m.Search),
    })

    manager.add(
      '/registration',
      isCandidateSitter
        ? {
            event_id: 'not_approved_sitter',
            component: () => IMPORT_MAP.routes.CandidateSitter.CandidateSitter().then(m => m.CandidateSitter),
          }
        : (checkRegistrationIncomplete() ?? {
            event_id: 'registration_completed',
            component: RedirectToRoot,
          })
    )

    manager.add('/registration/phone', makeRedirectRoute('/registration', 'outdated_phone_confirm'))

    manager.add('/registration/schedule', makeRedirectRoute('/schedule', 'outdated_schedule_registration'))

    {
      manager.add(
        '/announcements',
        checkIsCandidateSitter() ??
          checkRegistrationIncomplete() ??
          (user?.account_type === 'sitter'
            ? {
                event_id: 'announcements',
                component: RedirectToRoot,
              }
            : user?.account_type === 'parent'
              ? {
                  event_id: 'parent_announcements',
                  component: ParentAnnouncements,
                }
              : {
                  event_id: 'depersonalized_announcements',
                  component: DepersonalizedAnnouncements,
                })
      )

      manager.add(
        '/announcements/new',
        checkMissingAuth('unauth_announcements_new') ??
          checkRegistrationIncomplete('parent') ??
          checkAccountType('parent') ?? {
            event_id: 'new_announcement',
            component: NewAnnouncement,
          }
      )

      manager.add(
        '/announcements/:id',
        checkIsCandidateSitter() ??
          checkRegistrationIncomplete() ??
          (user?.account_type === 'parent'
            ? {
                event_id: 'details_announcement',
                event_params: { announcement_id: 'id' },
                component: Announcement,
              }
            : user?.account_type === 'sitter'
              ? {
                  event_id: 'announcements',
                  event_params: { announcement_id: 'id' },
                  component: Announcements,
                }
              : {
                  event_id: 'depersonalized_announcement',
                  component: PublicAnnouncement,
                })
      )

      manager.add(
        '/announcements/:id/edit',
        checkMissingAuth('unauth_edit_announcement', { announcement_id: 'id' }) ??
          checkRegistrationIncomplete('parent') ??
          checkAccountType('parent') ?? {
            event_id: 'edit_announcement',
            event_params: { announcement_id: 'id' },
            component: EditAnnouncement,
          }
      )
    }

    {
      manager.add(
        '/m',
        checkMissingAuth('unauth_conversations') ??
          checkIsCandidateSitter() ??
          checkRegistrationIncomplete() ??
          checkAccountType('sitter') ?? {
            event_id: 'conversations',
            component: MessagesLayout,
          }
      )

      manager.add(
        '/m/:token',
        checkMissingAuth('unauth_conversation', { token: 'token' }) ??
          checkIsCandidateSitter() ??
          checkRegistrationIncomplete() ?? {
            event_id: 'conversation',
            component: MessagesLayout,
            progress: false,
            event_params: { participant_user_id: 'token' },
            name: 'messages',
          }
      )
    }

    regionSlugs.forEach(prefix => {
      manager.add(
        `/${prefix}`,
        user
          ? {
              event_id: 'redirect_to_main',
              component: RedirectToRoot,
            }
          : null
      )
    })

    manager.add(
      '/complete',
      user?.account_type === 'parent' && user.registration_completed
        ? {
            event_id: 'registration_completed',
            component: () => IMPORT_MAP.routes.Settings.RegistrationComplete().then(m => m.Parent),
          }
        : user?.account_type === 'sitter' && user.registration_completed
          ? {
              event_id: 'registration_completed',
              component: () => IMPORT_MAP.routes.Settings.RegistrationComplete().then(m => m.Sitter),
            }
          : {
              event_id: 'redirect_to_root',
              component: RedirectToRoot,
            }
    )

    manager.add(
      '/settings',
      checkMissingAuth('unauth_settings') ??
        (user?.account_type === 'parent'
          ? {
              event_id: !user.registration_completed ? 'parent_registration' : 'settings',
              component: () => IMPORT_MAP.routes.Settings.ProfileParent().then(m => m.ProfileParent),
            }
          : user?.account_type === 'sitter'
            ? {
                event_id: !user.registration_completed ? 'sitter_registration' : 'settings',
                component: () => IMPORT_MAP.routes.Settings.ProfileSitter().then(m => m.ProfileSitter),
              }
            : null)
    )

    manager.add(
      '/history',
      checkMissingAuth('unauth_history') ??
        checkIsCandidateSitter() ??
        checkRegistrationIncomplete() ?? {
          event_id: 'history',
          component: () => IMPORT_MAP.routes.Settings.History().then(m => m.History),
        }
    )

    manager.add(
      '/billing',
      checkMissingAuth('unauth_billing') ??
        checkRegistrationIncomplete('parent') ??
        checkAccountType('parent') ?? {
          event_id: 'billing',
          component: () => IMPORT_MAP.routes.Settings.Billing().then(m => m.default),
        }
    )

    manager.add(
      '/billing/:step',
      checkMissingAuth('unauth_billing') ??
        checkRegistrationIncomplete('parent') ??
        checkAccountType('parent') ?? {
          event_id: 'billing',
          event_params: { step: 'step' },
          component: () => IMPORT_MAP.routes.Settings.Billing().then(m => m.default),
        }
    )

    manager.add(
      '/favorites',
      checkMissingAuth('unauth_favorites') ??
        checkIsCandidateSitter() ??
        checkRegistrationIncomplete() ?? {
          event_id: 'favorites',
          component: () => IMPORT_MAP.routes.Settings.Favorites().then(m => m.default),
        }
    )

    manager.add(
      '/ignored',
      checkMissingAuth('unauth_ignored') ??
        checkIsCandidateSitter() ??
        checkRegistrationIncomplete() ??
        (user?.account_type === 'sitter'
          ? {
              event_id: 'ignored',
              component: () => IMPORT_MAP.routes.Settings.Ignored().then(m => m.default),
            }
          : null)
    )

    manager.add(
      '/schedule',
      checkMissingAuth('unauth_schedule') ??
        (user?.account_type === 'sitter'
          ? {
              event_id: !user.registration_completed ? 'schedule_registration' : 'schedule',
              component: () => IMPORT_MAP.routes.Settings.Calendar().then(m => m.Calendar),
            }
          : null)
    )

    manager.add(
      '/trusted',
      checkMissingAuth('unauth_trusted') ??
        checkRegistrationIncomplete('parent') ??
        checkAccountType('parent') ?? {
          event_id: 'trusted',
          component: () => IMPORT_MAP.routes.Settings.Trusted().then(m => m.default),
        }
    )

    manager.add(
      '/trusting',
      checkMissingAuth('unauth_trusting') ??
        checkIsCandidateSitter() ??
        checkRegistrationIncomplete('sitter') ??
        checkAccountType('sitter') ?? {
          event_id: 'trusting',
          component: () => IMPORT_MAP.routes.Settings.Trusted().then(m => m.default),
        }
    )

    manager.add(
      '/notification-settings',
      checkMissingAuth('unauth_notification_settings') ??
        checkRegistrationIncomplete() ?? {
          event_id: 'notification_settings',
          component: () => IMPORT_MAP.routes.Settings.Notifications().then(m => m.NotificationSettings),
        }
    )

    manager.add('/notification_settings', makeRedirectRoute('/notification-settings', 'notification_settings'))

    manager.add(
      '/promocode',
      checkRegistrationIncomplete() ??
        (user
          ? {
              event_id: 'promocode',
              component: () => IMPORT_MAP.routes.Settings.Promocode().then(m => m.Promocode),
            }
          : {
              event_id: 'unauth_promocode',
              component: () => IMPORT_MAP.routes.Settings.PromocodeUnauth().then(m => m.Promocode),
            })
    )

    manager.add(
      '/referrals',
      checkMissingAuth('unauth_referrals') ??
        checkRegistrationIncomplete() ?? {
          event_id: 'referrals',
          component: () => IMPORT_MAP.routes.Settings.Referrals().then(m => m.Referrals),
        }
    )

    manager.add('/courses', {
      event_id: 'courses',
      component: () => IMPORT_MAP.routes.Settings.Courses().then(m => m.default),
    })

    manager.add(
      '/reviews',
      checkMissingAuth('unauth_reviews') ??
        checkRegistrationIncomplete() ?? {
          event_id: 'reviews',
          component: () => IMPORT_MAP.routes.Settings.Reviews().then(m => m.Reviews),
        }
    )

    manager.add(
      '/news',
      checkMissingAuth('news_unauth') ?? {
        event_id: 'news',
        progress: false,
        component: () => IMPORT_MAP.routes.News.News().then(m => m.News),
      }
    )

    manager.add('/playground', {
      regional: true,
      event_id: 'playground_landing',
      component: () => IMPORT_MAP.routes.Playground.Playground().then(m => m.Playground),
    })

    {
      manager.add(
        '/online',
        !user
          ? {
              event_id: 'online',
              component: LandingParent,
            }
          : null
      )
      manager.add(
        '/urgent-nanny',
        !user
          ? {
              event_id: 'urgent-nanny',
              component: LandingParent,
              regional: true,
            }
          : null
      )
      manager.add(
        '/comfort',
        !user
          ? {
              event_id: 'comfort',
              component: RedirectToRoot,
              regional: true,
            }
          : null
      )

      manager.add(
        '/part-time-nanny',
        !user
          ? {
              event_id: 'part-time-nanny',
              component: LandingParent,
              regional: true,
            }
          : null
      )

      manager.add(
        '/safety',
        !user
          ? {
              event_id: 'safety',
              component: LandingParent,
              regional: true,
            }
          : null
      )

      manager.add(
        '/nanny',
        !user
          ? {
              event_id: 'nanny',
              component: LandingParent,
              regional: true,
            }
          : null
      )

      manager.add(
        '/camp',
        !user
          ? {
              event_id: 'camp',
              component: LandingParent,
              regional: true,
            }
          : null
      )
    }

    manager.add('/babysitter', {
      regional: true,
      event_id: 'main_page_babysitter',
      component: () => IMPORT_MAP.routes.Static.LandingSitter().then(m => m.default),
    })

    manager.add('/babysitter/school', {
      regional: true,
      event_id: 'school_babysitters',
      component: () => IMPORT_MAP.routes.Static.School().then(m => m.default),
    })

    manager.add('/school', {
      event_id: 'redirect_to_school',
      component: RedirectToSchool,
    })

    manager.add('/school/babysitters', {
      event_id: 'school_babysitters',
      component: RedirectToSchool,
    })

    manager.add('/school/nanny', {
      event_id: 'school_nanny',
      component: () => IMPORT_MAP.routes.Static.SchoolNanny().then(m => m.SchoolNanny),
    })

    manager.add('/school-v2', {
      event_id: 'school_v2',
      component: RedirectToSchool,
    })

    manager.add('/school_v2', makeRedirectRoute('/school-v2', 'school_v2'))

    manager.add('/how-it-works', {
      event_id: 'how_it_works',
      component: () => IMPORT_MAP.routes.Static.HowItWorks().then(m => m.HowItWorks),
    })

    manager.add(
      '/sitter-screening',
      SITTER_SCREENING_ENABLED
        ? {
            event_id: 'sitter_screening',
            component: () => import('@app/routes/Static/components/LandingSitterCheck/LandingSitterCheck').then(m => m.LandingSitterCheck),
          }
        : null
    )

    manager.add('/account-deletion', {
      event_id: 'account_deletion',
      component: () => import('@app/routes/Static/components/AccountDeletion/AccountDeletion').then(m => m.AccountDeletion),
    })

    manager.add('/account-deletion/:id', {
      event_id: 'account_deletion',
      component: () => import('@app/routes/Static/components/AccountDeletion/AccountDeletion').then(m => m.AccountDeletion),
    })

    manager.add('/contacts', {
      event_id: 'contacts',
      component: () => IMPORT_MAP.routes.Static.Contacts().then(m => m.default),
    })

    manager.add('/faq', {
      event_id: 'faq',
      component: () => IMPORT_MAP.routes.Static.Faq().then(m => m.default),
    })

    manager.add('/insurance', {
      event_id: 'insurance',
      component: () => IMPORT_MAP.routes.Static.Insurance().then(m => m.default),
    })

    manager.add('/tos', {
      event_id: 'tos',
      component: createRedirect('/licence'),
    })

    manager.add(
      '/sitter-interactions',
      user?.account_type === 'sitter'
        ? {
            event_id: 'sitter_interactions',
            component: createRedirect('/rules'),
          }
        : null
    )

    manager.add('/sitter_interactions', makeRedirectRoute('/sitter-interactions', 'sitter_interactions'))

    navManager.items.forEach(item => {
      if (item.type !== 'item') return
      if (item.side) return
      if (item.link === '/rules' && user?.account_type !== 'sitter') return

      manager.add(item.link, {
        event_id: item.event_id,
        component: async () => createStaticPageRenderer(item),
      })

      item.aliases?.forEach(alias => {
        manager.add(alias, makeRedirectRoute(item.link, item.event_id))
      })
    })

    manager.addSubRoute(
      () => IMPORT_MAP.routes.Static.StaticLayoutWithMenu().then(m => m.default),
      manager => {
        navManager.items.forEach(item => {
          if (item.type !== 'item') return
          if (!item.side) return
          if (item.show && !item.show(user?.account_type ?? null)) return

          manager.add(item.link, {
            event_id: item.event_id,
            component: async () => createStaticPageRenderer(item),
          })

          item.aliases?.forEach(alias => {
            manager.add(alias, makeRedirectRoute(item.link, item.event_id))
          })
        })
      }
    )

    manager.add(
      '/affiliate',
      affiliatesEnabled
        ? {
            event_id: 'affiliate',
            component: () => IMPORT_MAP.routes.Affiliate.Affiliate().then(m => m.Affiliate),
          }
        : null
    )

    {
      manager.add('/giftcard', {
        regional: true,
        event_id: 'giftcard_order',
        component: () => IMPORT_MAP.routes.GiftCard.GiftCardOrder().then(m => m.default),
      })

      manager.add('/giftcard/:uuid', {
        regional: true,
        event_id: 'giftcard_order',
        event_params: { is_success: 'false' },
        component: () => IMPORT_MAP.routes.GiftCard.GiftCardDetails().then(m => m.default),
      })

      manager.add('/giftcard/:uuid/:isSuccess', {
        regional: true,
        event_id: 'giftcard_order',
        event_params: { is_success: 'isSuccess' },
        component: () => IMPORT_MAP.routes.GiftCard.GiftCardDetails().then(m => m.default),
      })
    }

    {
      manager.add('/quiz', {
        event_id: 'quiz',
        component: () => IMPORT_MAP.routes.Quiz.Quiz().then(m => m.Quiz),
      })

      manager.add('/quiz/watch', {
        event_id: 'quiz_watch',
        component: createRedirect('/quiz', true),
      })

      manager.add('/quiz/:slug/watch', {
        event_id: 'quiz_watch',
        event_params: { slug: 'slug' },
        component: () => IMPORT_MAP.routes.Quiz.QuizWatch().then(m => m.QuizWatch),
      })

      manager.add('/quiz/:slug', {
        event_id: 'quiz_game',
        event_params: { slug: 'slug' },
        component: () => IMPORT_MAP.routes.Quiz.QuizGame().then(m => m.QuizGame),
      })
    }

    {
      manager.add('/apps/:platform/:flavor', {
        event_id: 'apps',
        event_params: {
          platform: 'platform',
          flavor: 'flavor',
        },
        component: Apps,
      })

      manager.add('/apps/android/:flavor/latest.apk', {
        event_id: 'apps',
        event_params: {
          platform: 'platform',
          flavor: 'flavor',
        },
        component: ApkLink,
      })
    }

    manager.add('/payment/bind-card', {
      event_id: 'bind_card',
      component: () => IMPORT_MAP.routes.Internal.BindCard().then(m => m.BindCard),
    })

    manager.add('/payment/bind_card', makeRedirectRoute('/payment/bind-card', 'bind_card'))

    manager.add('/internal/tos-accept', {
      event_id: 'tos_accept',
      component: () => IMPORT_MAP.routes.Internal.TOSAccept().then(m => m.TOSAccept),
    })

    manager.add('/internal/tos_accept', makeRedirectRoute('/internal/tos-accept', 'tos_accept'))

    manager.add('/service-reviews', {
      event_id: 'service_reviews',
      component: () => import('@app/routes/ServiceReviews/ServiceReviews').then(m => m.ServiceReviews),
    })

    manager.add(
      '/telegram/code',
      checkMissingAuth('telegram_code_unauth') ?? {
        event_id: 'telegram_code',
        component: () => IMPORT_MAP.routes.Telegram.Code().then(m => m.TelegramCode),
      }
    )

    manager.add('/new-year-2021', {
      event_id: 'newyear2021',
      component: () => IMPORT_MAP.routes.NY2020.NY2020().then(m => m.NY2020),
    })

    manager.add('/new_year_2021', makeRedirectRoute('/new-year-2021', 'newyear2021'))

    manager.add('/email-confirmation', {
      event_id: 'email_confirmation',
      component: () => IMPORT_MAP.routes.Settings.EmailConfirmation().then(m => m.EmailConfirmation),
    })

    manager.add('/email_confirmation', makeRedirectRoute('/email-confirmation', 'email_confirmation'))

    manager.add('/email-auth', {
      event_id: 'email_auth',
      component: () => IMPORT_MAP.routes.Settings.EmailAuth().then(m => m.EmailAuth),
    })

    manager.add('/email_auth', makeRedirectRoute('/email-auth', 'email_auth'))

    manager.add('/email-token', {
      event_id: 'email_token',
      component: () => IMPORT_MAP.routes.Settings.EmailToken().then(m => m.EmailToken),
    })

    manager.add('/email_token', makeRedirectRoute('/email-token', 'email_token'))

    manager.add('/training-confirmation', {
      event_id: 'training_confirmation',
      component: () => IMPORT_MAP.routes.Settings.TrainingConfirmation().then(m => m.TrainingConfirmation),
    })

    manager.add('/training_confirmation', makeRedirectRoute('/training-confirmation', 'training_confirmation'))

    manager.add('/telegram-auth', {
      event_id: 'telegram_auth',
      component: () => IMPORT_MAP.routes.Settings.TelegramAuth().then(m => m.TelegramAuth),
    })

    manager.add('/telegram-auth/*', {
      event_id: 'telegram_auth',
      component: () => IMPORT_MAP.routes.Settings.TelegramAuth().then(m => m.TelegramAuth),
    })

    manager.routes.push(...(require('@app/routes/Academy/routes') as typeof import('@app/routes/Academy/routes')).default)

    if (process.env.MODE !== 'production') {
      const routes = (require('@app/routes/Polygon/routes') as typeof import('@app/routes/Polygon/routes')).default
      manager.routes.push(routes())
    }

    manager.add('/p/:token', {
      event_id: 'parent_profile',
      event_params: { profile_user_id: 'token' },
      component: ParentProfile,
    })

    manager.add('/s/:sitter_token', {
      event_id: 'sitter_profile',
      event_params: { profile_user_id: 'sitter_token' },
      component: SitterProfile,
    })

    manager.add('/s/:sitter_token/:description', {
      event_id: 'sitter_profile',
      event_params: { profile_user_id: 'sitter_token' },
      component: SitterProfile,
    })

    manager.add('/:sitter_token', {
      event_id: 'sitter_profile',
      event_params: { profile_user_id: 'sitter_token' },
      component: SitterProfile,
    })

    manager.add('/:sitter_token/:description', {
      event_id: 'sitter_profile',
      event_params: { profile_user_id: 'sitter_token' },
      component: SitterProfile,
    })

    return manager.routes
  }
)

class RouteManager {
  readonly routes: AsyncRoute[] = []

  add(path: string, route: Omit<AsyncRoute, 'path'> | null | typeof NO_ROUTE) {
    if (this.routes.find(r => r.path && r.path === path)) throw new Error(`Route with path "${path}" already exists`)
    if (!route) return
    if (route === NO_ROUTE) return
    this.routes.push({ ...route, path } as AsyncRoute)
  }

  addSubRoute(component: () => Promise<ComponentType<any>>, builder: (manager: RouteManager) => void) {
    const manager = new RouteManager()
    builder(manager)
    this.routes.push({ component, routes: manager.routes })
  }
}

const NO_ROUTE = Symbol('No Route')

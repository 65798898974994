import { ApiInaccurateLocation } from '@app/constants/ApiTypes/entities'

import { apiNormalize } from '@app/utils/apiNormalizer'
import { matchReduxAction } from '@app/utils/matchReduxAction'

import { getDepersonalizedAnnouncementsSearchMapDescriptor } from '@app/store/actions/depersonalized_announcements.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{ [key: string]: ApiInaccurateLocation }>({}, builder => {
  builder.defaultHandler = (state, action) => {
    if (matchReduxAction(action, getDepersonalizedAnnouncementsSearchMapDescriptor.shapes.fulfilled)) return state
    const { inaccurate_locations } = apiNormalize(action)
    return { ...state, ...inaccurate_locations }
  }
})

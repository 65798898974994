import pickBy from 'lodash/pickBy'

/**
 * Function to declare ans track optional parameters in object
 *
 * @example
 * type Text = {
 *   optional?: string
 *   required: number
 * }
 *
 * // error
 * defineAll<Text>({
 *   required: 1
 * })
 *
 * // pass
 * defineAll<Text>({
 *   optional: undefined,
 *   required: 1
 * })
 */
export function defineAll<T extends {}>(obj: DefineAll<T>): T {
  return pickBy(obj, value => typeof value !== 'undefined') as T
}

type DefineAll<T extends {}> = {
  [K in keyof Required<T>]: T[K] extends undefined ? T[K] | undefined : T[K]
}

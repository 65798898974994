import type { Locale } from '@app/constants/Locales'

import { IntlService } from '@app/services/IntlService'

import * as api from '@app/utils/api'
import type { ApplicationBridge } from '@app/utils/ApplicationBridge'
import moment from '@app/utils/moment'
import { promisify } from '@app/utils/promisify'
import { get as getTestFlights } from '@app/utils/testFlight'
import { urlEscaped } from '@app/utils/urlEscaped'
import { waitFor } from '@app/utils/waitFor'

import { Task } from '@app/packages/task/Task'

import { ApiActionBuilder } from '@app/store/apiMiddleware/builder'
import type { StoreState } from '@app/store/store'
import { createThunk, ThunkAction } from '@app/store/thunk'

import { getFeatures } from './api/features'
import {
  getAffiliatesDescriptor,
  getLinksDescriptor,
  getPlaygroundRequestsEventTypesDescriptor,
  getRegionDescriptor,
  getRegionsDescriptor,
  getSchoolsDescriptor,
  getStatisticsDescriptor,
  passData,
  setLocaleAction,
  setTestFlight,
} from './initial.descriptors'
import { createTask, withTaskContext } from './tasks'

export function getState(): ThunkAction<StoreState>
export function getState<R>(selector: (state: StoreState) => R): ThunkAction<R>
export function getState<R>(selector?: (state: StoreState) => R): ThunkAction<R> {
  return (_dispatch, getState) => {
    if (selector) return selector(getState())
    return getState() as any
  }
}

export const getStatistics = new ApiActionBuilder(getStatisticsDescriptor)
  .setInit(() => ({
    method: 'GET',
    endpoint: api.path('/api/v2/statistics?cache=1668184206422'),
    headers: api.headers(),
    bailout: ({ statistics }) => statistics.loaded,
  }))
  .build()

export const getRegions = new ApiActionBuilder(getRegionsDescriptor)
  .setInit(() => ({
    method: 'GET',
    endpoint: api.path('/api/v2/regions', { filter: 'all' }),
    headers: api.headers(),
    bailout: ({ regions }) => {
      if (regions.state.loading) return true
      if (regions.state.loadedAt) {
        return moment().isBefore(moment(regions.state.loadedAt).add(2, 'hours'))
      }
      return false
    },
  }))
  .build()

export const getRegion = new ApiActionBuilder(getRegionDescriptor)
  .setInit((id: string) => ({
    method: 'GET',
    endpoint: api.path(urlEscaped`/api/v2/regions/${id}`),
    headers: api.headers(),
  }))
  .build()

export function fetchRegion(id: string) {
  return createThunk((dispatch, getState) => {
    const state = getState()
    const region = state.regions.models[id]
    // we check if place exists, to ensure that region was obtained
    // by calling region api and not any other one that has region
    // as relationship
    if (region) {
      const place_id = region.relationships.default_place.data?.id
      if (place_id) {
        const place = state.places[place_id]
        if (place) return
      }
    }
    return dispatch(getRegion(id))
  })
}

const localeImport = {
  ru: () => import('moment/locale/ru'),
}

export function setLocale(nextLocale: Locale) {
  return createThunk(async (dispatch, getState) => {
    const { locale, routing } = getState()

    if ((!IS_BROWSER && routing.isInitial) || locale !== nextLocale) {
      await IntlService.shared.load(nextLocale)
      await localeImport[nextLocale]?.()
      moment.locale(nextLocale)

      dispatch(setLocaleAction({ locale: nextLocale }))
    }
  })
}

export function restoreLocale() {
  return createThunk(async (_dispatch, getState) => {
    const { locale } = getState()
    await Promise.all([
      (async () => {
        await localeImport[locale]?.()
        moment.locale(locale)
      })(),
      import('@app/utils/addressTools').then(({ setYMapsLocale }) => setYMapsLocale(locale)),
    ])
  })
}

export const getPlaygroundRequestsEventTypes = new ApiActionBuilder(getPlaygroundRequestsEventTypesDescriptor)
  .setInit(() => ({
    method: 'GET',
    endpoint: api.path('/api/v2/playground_requests/event_types'),
    headers: api.headers(),
    bailout: state => state.playground_event_types.loaded,
  }))
  .build()

export const getSchools = new ApiActionBuilder(getSchoolsDescriptor)
  .setInit(() => ({
    method: 'GET',
    endpoint: api.path('/api/v2/schools'),
    headers: api.headers(),
  }))
  .build()

export const getAffiliates = new ApiActionBuilder(getAffiliatesDescriptor)
  .setInit(() => ({
    method: 'GET',
    endpoint: api.path('/api/v2/affiliates'),
    headers: api.headers(),
  }))
  .build()

export function restoreTestFlights() {
  return createThunk((dispatch, _getState, { cookies }) => {
    const value = getTestFlights(cookies)

    return dispatch(setTestFlight(value.flights))
  })
}

export const getLinks = new ApiActionBuilder(getLinksDescriptor)
  .setInit(() => ({
    method: 'GET',
    endpoint: api.path('/api/v2/links'),
    headers: api.headers(),
  }))
  .build()

export function setRenderData<T = any>(payload: T) {
  return passData(payload)
}

export function getContext() {
  return createThunk((_dispatch, _getState, context) => context)
}

export function getMount() {
  return createThunk((_dispatch, _getState, { mount }) => mount)
}

export function getProgress() {
  return createThunk((_dispatch, _getState, { progress }) => progress)
}

export function withProgress<T>(promise: T, blocking = false) {
  return createThunk((_dispatch, _getState, { progress }) => progress.wrap(promisify(promise), blocking))
}

export function withProgressAction<T>(action: ThunkAction<T>, blocking = false) {
  return createThunk((dispatch, _getState, { progress }) => progress.wrap(promisify(dispatch(action)), blocking))
}

export function getApplicationBridge() {
  return createThunk((_dispatch, _getState, { applicationBridge }) => applicationBridge)
}

export function getTwilioManager() {
  return createThunk((_dispatch, _getState, context) => context.twilio)
}

export function getFirebaseManager() {
  return createThunk(async (_dispatch, _getState, context) => {
    try {
      const { manager } = await waitFor(() => (context.firebaseManager || context.firebaseManager === null ? { manager: context.firebaseManager } : null))
      return manager
    } catch {
      return null
    }
  })
}

export function setApplicationBridge(bridge: ApplicationBridge) {
  return createThunk((_dispatch, _getState, ctx) => {
    ctx.applicationBridge = bridge
  })
}

export function getIconsCache() {
  return createThunk((_dispatch, _getState, { iconCache }) => iconCache)
}

export function registerPreload(moduelId: string) {
  return createThunk((_dispatch, _getState, { preloadSet }) => preloadSet?.add(moduelId))
}

export function getRegisteredPreloads() {
  return createThunk((_dispatch, _getState, { preloadSet }) => Array.from(preloadSet ?? []))
}

export const getFeaturesTask = createTask('Features task', dispatch =>
  Task.create(ctx => async () => {
    return await dispatch(withTaskContext(ctx, getFeatures()))
  })
)

export const getPromiseManager = () => {
  return createThunk((_dispatch, _getState, { promiseManager }) => promiseManager)
}

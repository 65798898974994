import { intoResultAsync } from '@app/packages/Result/Result'

export class PromiseManager {
  private map: Map<symbol, Promise<any>> = new Map()
  /** Holds timestamp of promise for corresponding label. -1 for pending promise  */
  private timestampMap: Map<symbol, number> = new Map()

  get<T>(label: symbol): Promise<T> | undefined {
    return this.map.get(label)
  }

  create<T>(
    label: symbol,
    creator: () => Promise<T>,
    {
      freshness = 0,
      discard = res => res.error,
    }: {
      /**
       * Time (millisecond) to consider existing promise valid.
       * Use 0 to reuse current pending promise,
       * or use -1 to forcefully create new one
       */
      freshness?: number
      discard?: (value: { error: false; value: T } | { error: true; value: Error }) => boolean
    } = {}
  ): Promise<T> {
    const existing = this.map.get(label)
    if (existing && freshness > -1) {
      const ts = this.timestampMap.get(label)!
      if (freshness === 0 && ts === -1) return existing
      if (new Date().getTime() - ts <= freshness) return existing
    }

    const promise = creator()
    this.timestampMap.set(label, -1)
    this.map.set(label, promise)
    const promiseResult = intoResultAsync(promise)
    promiseResult.then(result => {
      this.timestampMap.set(label, new Date().getTime())
      if (discard(result)) {
        this.map.delete(label)
      }
    })
    return promise
  }

  discard(label: symbol) {
    this.map.delete(label)
  }
}

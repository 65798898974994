import { useCallback, useMemo } from 'react'
import * as valita from '@badrap/valita'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import { defineMessages } from 'react-intl'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { v4 as uuid } from 'uuid'

import { IMPORT_MAP } from '@app/importMap'

import { AbortError } from '@app/errors/AbortError'
import { IntlError } from '@app/errors/IntlError'

import type { GeocodeValidator } from '@app/utils/addressTools'
import { assertNever } from '@app/utils/assertNever'
import { createSortHandler } from '@app/utils/createSortHandler'
import { ensureApiType } from '@app/utils/ensureApiType'
import { ensureType } from '@app/utils/ensureType'
import { createLocalStorage } from '@app/utils/localStorage'
import { maybeType } from '@app/utils/maybeType'
import { useAppDispatch } from '@app/utils/redux'
import { sleep } from '@app/utils/sleep'
import { tryJSONparse } from '@app/utils/tryJSONparse'

import { createAddress } from '@app/packages/geo/Address'
import { intoResultAsync, unwrapOr } from '@app/packages/Result/Result'

import { useEvent } from '@app/hooks/useEvent'

import { getPlacesByLocation } from '@app/store/actions/api/places'
import { getMount, getState } from '@app/store/actions/initial'
import { getPlaceById } from '@app/store/actions/region'
import { profileMapLocationSelector } from '@app/store/selectors/profile'
import { regionsModelsSelector } from '@app/store/selectors/regions'

import {
  ControlledSuggestInputButton,
  ControlledSuggestInputFetchSuggestionsCallback,
  ControlledSuggestInputGetValueCallback,
  ControlledSuggestInputOnChangeCallback,
  ControlledSuggestInputRenderSuggestionCallback,
} from '@app/components/SuggestInput/ControlledSuggestInput'

import { PlaceInputInputValue, PlaceInputLabelExtractor, PlaceInputOnChangeCallback, PlaceInputResultValue, processLabel } from './shared'

import classes from '../AddressInput/AddressInput.module.scss'

export const useController = (
  value: PlaceInputInputValue | null,
  initiator: string,
  onChange: PlaceInputOnChangeCallback,
  extractLabel: PlaceInputLabelExtractor,
  error: Error | boolean | undefined,
  detectLocation: boolean,
  map: boolean
) => {
  const dispatch = useAppDispatch()
  const defaultMapLocation = useSelector(profileMapLocationSelector)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const id = useMemo(() => uuid(), [value])

  const getValue = useCallback<ControlledSuggestInputGetValueCallback>(
    (val, current) => {
      if (current) return value?.locality?.attributes?.name ?? value?.place.attributes.address ?? ''

      return val?.split(':').at(1) ?? ''
    },
    [value?.locality?.attributes?.name, value?.place.attributes.address]
  )

  const fetchSuggestions = useEvent<ControlledSuggestInputFetchSuggestionsCallback>(async (value, isDirty, signal) => {
    const tvalue = (value || '').trim()
    if (!tvalue || !isDirty) {
      const lastAddresses = lastAddressesStorage.get()
      return uniqBy(
        [
          ...lastAddresses.map(c => [c.id, c.value].join(':')),
          ...dispatch(getState(defaultRegionsSelector)).flatMap(r =>
            r.relationships.default_place.data ? [[r.relationships.default_place.data.id, processLabel(r.attributes.name)].join(':')] : []
          ),
        ],
        b => b.split(':')[1]
      ).slice(0, 5)
    }

    await sleep(500)
    if (signal.aborted) throw new AbortError('Place input fetch was aborted')
    const { getSuggestions } = await IMPORT_MAP.utils.addressTools()
    const suggestions = await getSuggestions(tvalue, initiator)
    return uniq(suggestions.map(s => ['', s.value].join(':')))
  })

  const getPlaceByLabel = useCallback(
    async (value: string, signal?: AbortSignal) => {
      const { getGeocodeData } = await IMPORT_MAP.utils.addressTools()
      const dataResult = await intoResultAsync(getGeocodeData(value, { validate: isCityValidator }))

      if (signal?.aborted) return

      if (dataResult.error) {
        return { error: true as const, payload: dataResult.value }
      }

      const data = dataResult.value

      if (!data) return null

      return (await dispatch(getPlacesByLocation({ latitude: data.location.lat, longitude: data.location.lon })))!
    },
    [dispatch]
  )

  const renderSuggestion = useCallback<ControlledSuggestInputRenderSuggestionCallback>(
    (val, current) => {
      if (current) {
        return value?.locality?.attributes?.name ?? value?.place.attributes.address ?? ''
      }
      return val?.split(':').at(1)
    },
    [value?.locality?.attributes?.name, value?.place.attributes.address]
  )

  const handleSelected = useEvent<ControlledSuggestInputOnChangeCallback>(async (value, stringValue) => {
    if (!value) {
      if (!stringValue) onChange(null)
      return
    }

    const [id, label] = value.split(':')

    const placeResponse = (await (id ? dispatch(getPlaceById(id)) : getPlaceByLabel(label)))!

    if (!placeResponse) {
      onChange(null)
      return
    }

    if (placeResponse.error) throw placeResponse.payload

    const result: PlaceInputResultValue = {
      place: placeResponse.payload.data,
      region: placeResponse.payload.included.find(ensureApiType('regions'))!,
      country: placeResponse.payload.included.find(ensureApiType('countries')) ?? null,
      locality: placeResponse.payload.included.find(ensureApiType('localities')) ?? null,
    }

    addToLastAddresses(result.place.id, extractLabel(result))

    onChange(result)
  })

  const buttons = useMemo(
    () =>
      map
        ? ensureType<ControlledSuggestInputButton[]>([
            {
              icon: 'pin',
              className: cn(classes.map_icon, { [classes.has_error]: !!error }),
              onPress(update) {
                dispatch(getMount())!.push(
                  IMPORT_MAP.modals.AddressMap().then(m => m.AddressMap),
                  {
                    props: {
                      address: value
                        ? createAddress({
                            label: value.locality?.attributes?.name ?? value.place.attributes.address,
                            location: {
                              lat: value.place.attributes.latitude,
                              lon: value.place.attributes.longitude,
                            },
                          })
                        : defaultMapLocation,
                      confirmAddress: address => {
                        update(`:${address.label}`)
                      },
                      detectLocation,
                    },
                  }
                )
              },
            },
          ])
        : [],
    [defaultMapLocation, detectLocation, dispatch, error, map, value]
  )

  return {
    id,
    getValue,
    fetchSuggestions,
    renderSuggestion,
    handleSelected,
    buttons,
  }
}

const messages = defineMessages({
  error_city: 'Укажите город',
})

class LocalityMissingError extends IntlError {}

const isCityValidator: GeocodeValidator = (label, object) => {
  switch (label) {
    case 'yandex': {
      const locality = object?.metaDataProperty?.GeocoderMetaData?.Address?.Components?.find(c => c.kind === 'locality' || c.kind === 'province')
      if (!locality) throw LocalityMissingError.createWithDescriptor('Address is not locality', messages.error_city)
      break
    }
    case 'google': {
      const locality = object.address_components.find(c => !!c.types.includes('locality'))
      if (!locality) throw LocalityMissingError.createWithDescriptor('Address is not locality', messages.error_city)
      break
    }
    default:
      assertNever(label)
  }
}

const defaultRegionsSelector = createSelector([regionsModelsSelector], regions => {
  return Object.values(regions)
    .filter(r => r.attributes.type === 'city')
    .sort(createSortHandler(r => [-parseFloat(r.id)]))
})

const lastAddressesStorage = createLocalStorage(
  'kidsout__last-places',
  data => maybeType(valita.array(RT_LastAddress), unwrapOr(tryJSONparse(data), [])) ?? [],
  data => JSON.stringify(data)
)

const RT_LastAddress = valita.object({
  id: valita.string().assert(v => v.trim().length > 0),
  value: valita.string().assert(v => v.trim().length > 0),
})

function addToLastAddresses(id: string, value: string) {
  try {
    const data = lastAddressesStorage.get()
    const index = data.findIndex(v => v.id === id)
    if (index !== -1) data.splice(index, 1)
    data.unshift({ id, value })
    lastAddressesStorage.set(data.slice(0, 5))
  } catch {}
}

import { Task } from '@app/packages/task/Task'

import { getContacts } from './api/contacts'
import { createTask, withTaskContext } from './tasks'

export const getContactsTask = createTask('Get contacts', dispatch =>
  Task.create(ctx => async () => {
    await dispatch(withTaskContext(ctx, getContacts()))
  })
)

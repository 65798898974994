import { intoError, Result, resultError, resultOk } from '@app/packages/Result/Result'

import { StoreState } from '@app/store/store'
import { createThunk } from '@app/store/thunk'

import { ActionRequiredError } from './ActionRequiredError'

export type ResultSelector<T> = (state: StoreState) => Result<T>

export const intoSelectorResult = <T>(cb: () => T) => {
  try {
    return resultOk(cb())
  } catch (e) {
    return resultError(intoError(e))
  }
}

export const resolveSelectorResult = <T>(selector: (state: StoreState) => Result<T>) =>
  createThunk(async (dispatch, getState) => {
    while (true) {
      const state = getState()
      const res = selector(state)
      if (res.error) {
        if (res.value instanceof ActionRequiredError) {
          await dispatch(res.value.action)
          continue
        } else {
          throw res.value
        }
      }
      return res.value
    }
  })

export const unwrapSelectorResult = <T>(val: Result<T>) => {
  if (val.error) throw val.value
  return val.value
}

import { createSelector } from 'reselect'

import { IntlService } from '@app/services/IntlService'

import { headers } from '@app/utils/api'
import { toE164 } from '@app/utils/phoneTools/toE164'

import { StoreState } from '@app/store/store'

import { sessionSelector } from './session'

export const stateSelector = (state: StoreState) => state

export const configSelector = (state: StoreState) => state.config

export const serverSelector = (state: StoreState) => state.server

export const avatarsSelector = (state: StoreState) => state.avatars
export const locationsSelector = (state: StoreState) => state.locations

export const metaSelector = (state: StoreState) => state.meta

export const isAppSelector = (state: StoreState) => state.config.isApp

export const supportAvatarUrlSelector = (state: StoreState) => state.config.supportAvatarUrl

export const supportPhoneSelector = (state: StoreState) => {
  if (state.profile.user?.account_type === 'parent' || state.profile.user?.account_type === 'sitter') {
    const regionid = state.profile.user.region_id
    if (!regionid) return null
    const region = state.regions.models[String(regionid)]
    if (!region) return null
    return toE164(state.profile.user.account_type === 'parent' ? region.attributes.parent_support_phone : region.attributes.sitter_support_phone)
  }

  return null
}

export const affiliatesSelector = (state: StoreState) => state.affiliates.list

export const localeSelector = (state: StoreState) => state.locale

export const intlSelector = createSelector([localeSelector], _locale => IntlService.shared.current)

export const headersSelector = createSelector([sessionSelector, localeSelector, serverSelector, configSelector], (session, locale, server, config) =>
  headers()({ session, locale, server, config })
)

export const cloudPaymentsIdSelector = (state: StoreState) => state.config.cloudpayments

export const statisticsSelector = (state: StoreState) => state.statistics

export const roundedSittersNumSelector = (state: StoreState) => Math.floor(state.statistics.active_sitters / 100) * 100

export const metaCorpEnabledSelector = (state: StoreState) => state.config.country !== 'RU' || state.config.metaCorpEnabled

export const renderDataSelector = (state: StoreState) => state.render

export const affiliatesEnabledSelector = (state: StoreState) => state.config.country.toLocaleUpperCase() === 'RU'

/**
 * AutoSizeTextArea typing is wrong and requires
 * following two properties to be present even though they are optional
 */
export const TEXTAREA_TYPE_FIX: {
  onPointerEnterCapture: undefined
  onPointerLeaveCapture: undefined
} = {} as any

import React, { FunctionComponent, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'

import { SUPPORT_OFFICE_HOURS } from '@app/constants/Misc'

import { WithPreload } from '@app/utils/componentPreload'
import { format } from '@app/utils/phoneTools/format'
import { toE164 } from '@app/utils/phoneTools/toE164'
import { useRouter } from '@app/utils/routing/hooks'
import { pathIsSubPathOf } from '@app/utils/url'

import { resolveSelectorResult } from '@app/packages/selectorResult/SelectorResult'
import { useSelectorResultValue } from '@app/packages/selectorResult/useSelectorResult'

import { contactsModelsSelector, createContactDataSelector } from '@app/store/selectors/contacts'

interface Props {
  className?: string
  phoneClassName?: string
  officeHourseClassName?: string
}

const ContactPhone: FunctionComponent<Props> & WithPreload = ({ className, phoneClassName, officeHourseClassName }) => {
  const { formatMessage } = useIntl()
  const router = useRouter()
  const dataSelectorType = useMemo(() => {
    if (pathIsSubPathOf('/babysitter/school', router.match.pathnameBase)) return 'school'
    if (pathIsSubPathOf('/babysitter', router.match.pathnameBase)) return 'sitter'
    return 'main'
  }, [router.match.pathnameBase])
  const dataSelector = useMemo(() => createContactDataSelector(dataSelectorType), [dataSelectorType])
  const data = useSelectorResultValue(dataSelector)

  return (
    <div className={className}>
      <a className={phoneClassName} href={`tel:${toE164(data.phone)}`}>
        {format(data.phone)}
      </a>
      <div className={officeHourseClassName}>{formatMessage(messages.hours, SUPPORT_OFFICE_HOURS)}</div>
    </div>
  )
}

ContactPhone.preload = () => async dispatch => {
  await dispatch(resolveSelectorResult(contactsModelsSelector))
}

export default ContactPhone

const messages = defineMessages({
  hours: 'Ежедневно с {start} до {end}',
})

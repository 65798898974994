import { ApiTimeZone } from '@app/constants/ApiTypes/entities'

import { Point } from '@app/packages/geo/Point'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'

export const getTimezonesDescriptor = new ApiActionBuilderDescriptor().setLabel('getTimezones').setShape<{ data: ApiTimeZone[] }>()
export const getTimezonesByLocationDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('getTimezonesByLocation')
  .setShape<{ data: ApiTimeZone }, { location: Point }>()

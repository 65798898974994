import { ApiTimeZone } from '@app/constants/ApiTypes/entities'

import { getTimezonesDescriptor } from '@app/store/actions/api/timezones.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<ApiTimeZone[] | null>(null, builder => {
  builder.addCase(getTimezonesDescriptor.shapes.fulfilled, (_state, action) => {
    return action.payload.data
  })
})

import mapValues from 'lodash/mapValues'
import pickBy from 'lodash/pickBy'

import { ApiRegion } from '@app/constants/ApiTypes/entities'
import { ISO8601 } from '@app/constants/Misc'

import { excludeAbortError } from '@app/errors/AbortError'

import { createSortHandler } from '@app/utils/createSortHandler'
import moment from '@app/utils/moment'
import { normalize } from '@app/utils/normalizer'

import { getContactsDescriptor } from '@app/store/actions/api/contacts.descriptors'
import { getRegionDescriptor, getRegionsDescriptor, getSchoolsDescriptor } from '@app/store/actions/initial.descriptors'
import { setProfilePlaceActionDescriptor } from '@app/store/actions/profile.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  state: {
    loaded: boolean
    loading: boolean
    loadedAt: ISO8601 | null
    error: Error | null
  }
  list: string[]
  models: { [key: string]: ApiRegion }
}>(
  {
    state: {
      loaded: false,
      loading: false,
      loadedAt: null,
      error: null,
    },
    list: [],
    models: {},
  },
  builder => {
    builder.addCase(getRegionsDescriptor.shapes.pending, (state, _action) => {
      return { ...state, state: { ...state.state, loading: true, error: null } }
    })

    builder.addCase(getRegionsDescriptor.shapes.rejected, (state, action) => {
      return { ...state, state: { ...state.state, loading: false, error: excludeAbortError(action.payload) } }
    })

    builder.addCase(getRegionsDescriptor.shapes.fulfilled, (state, action) => {
      let { regions } = normalize(action.payload)

      if (regions) {
        regions = replaceDashes(regions)
      }

      return {
        ...state,
        list: [...action.payload.data]
          .sort(createSortHandler(c => [c.attributes.type === 'world' ? 0 : c.attributes.type === 'country' ? 1 : 2, -Number(c.id)]))
          .map(c => c.id),
        models: { ...state.models, ...regions },
        state: { ...state.state, loading: false, loaded: true, loadedAt: moment().format() },
      }
    })

    builder.addCases(
      [
        getContactsDescriptor.shapes.fulfilled,
        getSchoolsDescriptor.shapes.fulfilled,
        getRegionDescriptor.shapes.fulfilled,
        setProfilePlaceActionDescriptor.shapes.fulfilled,
      ],
      (state, action) => {
        let { regions } = normalize(action.payload)

        if (!regions) return state

        regions = replaceDashes(regions)
        regions = pickBy(regions, r => !!r.relationships.default_place.data || !state.models[r.id])

        return { ...state, models: { ...state.models, ...regions } }
      }
    )
  }
)

/**
 * replace dashes with non-breaking hyphens in region name
 * main reason is Rostov-on-Don
 */
const replaceDashes = (regions: { [key: string]: ApiRegion }) => {
  return mapValues(regions, c => ({
    ...c,
    attributes: {
      ...c.attributes,
      name: c.attributes.name.replace(/-/gi, '\u2011'),
      prepositional_name: c.attributes.prepositional_name.replace(/-/gi, '\u2011'),
    },
  }))
}

import React, { createContext, CSSProperties, FunctionComponent, ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { IMPORT_MAP } from '@app/importMap'

import { WithPreload } from '@app/utils/componentPreload'
import { ensureType } from '@app/utils/ensureType'
import { noop } from '@app/utils/noop'
import { useOverflowContext } from '@app/utils/OverflowContext'
import { useAppDispatch } from '@app/utils/redux'
import { createStructuredSelector } from '@app/utils/reselect'
import { useRouter } from '@app/utils/routing/hooks'
import { trimRegionalRoute } from '@app/utils/routing/region'
import { normalizePath } from '@app/utils/url'

import { useDeviceType } from '@app/hooks/useDeviceType'
import { useEvent } from '@app/hooks/useEvent'
import { useLayoutEffect } from '@app/hooks/useLayoutEffect'

import { requireTOSAccept } from '@app/store/actions/requireTosAccept'
import { uiSetBannersVisible } from '@app/store/actions/ui.descriptors'
import { isAppSelector } from '@app/store/selectors/misc'
import { profileUserSelector } from '@app/store/selectors/profile'
import { availableRegionsSlugsSelector } from '@app/store/selectors/regions'
import { bannersVisibleSelector } from '@app/store/selectors/ui'

import { AppBanner } from '@app/components/AppBanner/AppBanner'
import { ConnectionStatusBanner } from '@app/components/ConnectionStatusBanner/ConnectionStatusBanner'
import { ControlClasses } from '@app/components/ControlClasses/ControlClasses'
import { SlideUp } from '@app/components/SlideUp/SlideUp'
import { WithVisibleHook } from '@app/components/TopNotification/TopNotification'

import { Footer } from './Footer'
import { Header, HeaderProps } from './Header'
import { useNavItems } from './navItems'
import { matchCurrentItem } from './navItemsUtils'
import { SideMenuLayout, SideMenuOnOpenedItemsChangeHandler } from './SideMenuLayout'

import './LayoutMilk.scss'

export interface LayoutMilkProps {
  className?: string
  /** event name for page in kebab-case */
  page: string
  isApp?: boolean
  header?: Partial<HeaderProps> | false
  footer?: boolean
  banners?: boolean
  sideMenu?: boolean
  /** whether overflow context should be increased */
  fixed?: boolean
  /** whether content after header should be pushed out of header */
  offset?: boolean
  /**
   * (default: true)
   * if true then layout becomes flex layout with direction: column and content gets wrapped in flex item with "flex: 1".
   * If false layout uses a hack of "height: 100%". This way layout and all its parent elements has css "height: 100%".
   * So if you use IntroWrapper which relies on this hack "flex" is undesireable.
   *
   * In short "height: 100%" is hack for iPhone to create block of 100% height. Using 100vh on block will result height
   * slightly bigger than 100% as it takes height of browser controls.
   */
  flex?: boolean
  /** what background color of layout should be */
  color?: 'gray'
  /**
   * children can be a function which receives headerHeight function.
   * Useful when you put absolutely posiioned element and want to know header height.
   * Right now used in NewsAndMessages layout to render chat
   */
  children: ReactNode | ((data: ChildrenRendererProps) => ReactNode)
}

/** Third version of kidsout layout named Milk */
export const LayoutMilk: FunctionComponent<LayoutMilkProps> & WithPreload & { classes: typeof classes } = ({
  className,
  page,
  isApp: localIsApp,
  header = {},
  footer = true,
  banners = true,
  sideMenu = true,
  offset = true,
  flex = true,
  fixed = false,
  color,
  children,
}) => {
  const router = useRouter()
  const overflowContext = useOverflowContext()
  const dispatch = useAppDispatch()
  const device = useDeviceType()

  const headerRef = useRef<HTMLDivElement>(null)
  const headerMainRef = useRef<HTMLDivElement>(null)

  const { isApp: globalIsApp, regions } = useSelector(stateSelector)

  const isApp = localIsApp || globalIsApp

  const [headerOffset, setHeaderOffset] = useState<null | number>(headerHeight)
  const [topHeaderOffset, setTopHeaderOffset] = useState<number>(topHeaderHeight)
  const [bannersCount, setBannersCount] = useState(0)

  const pathname = trimRegionalRoute(regions, normalizePath(router.location.pathname))

  const showSecondaryMenu = header && !header.showSecondary ? false : true

  const headerNavItems = useNavItems(pathname, 'header')
  const sideNavItems = useNavItems(pathname, 'side')

  const bannersBlock = <Banners onBannerCountChange={setBannersCount} visible={!isApp && banners} />

  const currentHeaderItems = useMemo(() => matchCurrentItem(pathname, headerNavItems), [headerNavItems, pathname])
  const currentSideItems = useMemo(() => matchCurrentItem(pathname, sideNavItems), [sideNavItems, pathname])

  const headerOffsetStyle = useMemo<CSSProperties>(() => {
    if (IS_BROWSER && headerOffset) return { height: headerOffset + 'px' }
    return {}
  }, [headerOffset])

  const hasSecondRow = !!currentHeaderItems[0]?.children?.length

  const childrenProps = useMemo<ChildrenRendererProps>(() => {
    if (isApp) return { headerHeight: 0, topHeaderHeight: 0 }
    const height = IS_BROWSER && headerOffset ? headerOffset : hasSecondRow ? INITIAL_HEADER_HEIGHT + INITIAL_SUBHEADER_HEIGHT : INITIAL_HEADER_HEIGHT
    return { headerHeight: height, topHeaderHeight: topHeaderOffset }
  }, [hasSecondRow, headerOffset, isApp, topHeaderOffset])

  const handleOpenedItemsChange = useEvent<SideMenuOnOpenedItemsChangeHandler>(items => {
    if (items['academy']) {
      IMPORT_MAP.actions.academy().then(({ restoreCart }) => {
        dispatch(restoreCart())
      })
    }
  })

  /* effects */

  useEffect(() => {
    if (currentHeaderItems?.[0]?.id === 'academy') {
      IMPORT_MAP.actions.academy().then(({ restoreCart }) => {
        dispatch(restoreCart())
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentHeaderItems?.[0]?.id])

  useEffect(() => {
    let running = true

    requestAnimationFrame(function runner() {
      if (!running) return
      const headerEl = headerRef.current
      if (headerEl) {
        const height = headerEl.clientHeight
        headerHeight = height
        setHeaderOffset(height)
      } else {
        setHeaderOffset(0)
      }
      const headerMainEl = headerMainRef.current
      if (headerMainEl) {
        const topHeight = headerMainEl.clientHeight
        topHeaderHeight = topHeight
        setTopHeaderOffset(topHeight)
      } else {
        setTopHeaderOffset(0)
      }
      requestAnimationFrame(runner)
    })

    const timeout = setTimeout(() => {
      running = false
    }, 500)

    return () => {
      running = false
      clearTimeout(timeout)
    }
  }, [bannersCount, hasSecondRow, showSecondaryMenu, device])

  useEffect(() => {
    if (TOSModalOpened) return
    TOSModalOpened = true
    dispatch(requireTOSAccept())
      .catch(noop)
      .then(() => {
        TOSModalOpened = false
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!fixed) return

    overflowContext.increment()
    return () => {
      overflowContext.decrement()
    }
  }, [fixed, overflowContext])

  /* render */

  const childrenContent = (
    <LayouMilkContext.Provider value={childrenProps}>{typeof children === 'function' ? children(childrenProps) : children}</LayouMilkContext.Provider>
  )

  if (isApp) return childrenContent

  const content = (
    <section
      className={cn(
        ControlClasses.max_height,
        'layout-milk',
        `layout-milk--color-${color || 'white'}`,
        {
          'layout-milk--flex': flex,
          'layout-milk--no-header': !header,
          'layout-milk--with-secondary': !!header && !!currentHeaderItems[0]?.children,
          ['m-' + page]: page,
        },
        className
      )}
    >
      <div className="layout-milk__header" ref={headerRef}>
        <div className={cn('layout-milk__fixed-banners')}>{bannersBlock}</div>
        {header && (
          <Header
            {...header}
            currentMainItem={currentHeaderItems[0] ?? undefined}
            currentSecondaryItem={currentHeaderItems[1] ?? undefined}
            items={headerNavItems}
            mainRef={headerMainRef}
          />
        )}
      </div>
      {offset && <div className="layout-milk__header-offset" style={headerOffsetStyle} />}

      {flex ? <div className="layout-milk__content">{childrenContent}</div> : childrenContent}

      {footer && <Footer navItems={sideNavItems} />}
    </section>
  )

  if (!sideMenu) return content

  return (
    <SideMenuLayout currentItems={currentSideItems} items={sideNavItems} onOpenedItemsChange={handleOpenedItemsChange}>
      {content}
    </SideMenuLayout>
  )
}

type LayouMilkContextDescriptor = { headerHeight: number; topHeaderHeight: number }

const LayouMilkContext = createContext<LayouMilkContextDescriptor>({ headerHeight: 0, topHeaderHeight: 0 })

export const LayouMilkContextConsumer = LayouMilkContext.Consumer

export const useLayoutMilkContext = () => useContext(LayouMilkContext)

/**
 * "grow" is flex: 1, so section takes all space. Use in conjunction with "flex={true}" prop on Layout. Otherwise it has no effect.
 */
type SectionModifier = 'grow' | 'tall' | 'color' | 'color-gray' | 'color-blue' | 'decorated' | 'decorated-left' | 'decorated-right'

const classes = {
  /**
   * adds block of header height,
   * so content appears undex header
   */
  section: (...modifiers: SectionModifier[]) => ['layout-milk__section', ...modifiers.map(m => `layout-milk__section--${m}`)].join(' '),
  /**
   * limits width of section to 1000px,
   * must be used inside section
   */
  sectionWrapper: 'layout-milk__wrapper',
  /** h1 title */
  sectionTitle: 'layout-milk__section-title',
  /** h2 title */
  sectionSubtitle: 'layout-milk__section-subtitle',
  sectionCutter: 'layout-milk__section-cutter',
  sectionText: 'layout-milk__section-text',
  sectionCols: 'layout-milk__section-cols',
  sectionColsItem: 'layout-milk__section-cols-item',
}

LayoutMilk.preload = () => async dispatch => {
  await dispatch(Footer.preload())
}

LayoutMilk.classes = classes

let TOSModalOpened: boolean = false

const INITIAL_HEADER_HEIGHT = 72
const INITIAL_SUBHEADER_HEIGHT = 54

let headerHeight: null | number = null
let topHeaderHeight: number = INITIAL_HEADER_HEIGHT

const stateSelector = createStructuredSelector({
  isApp: isAppSelector,
  regions: availableRegionsSlugsSelector,
})

type ChildrenRendererProps = { headerHeight: number; topHeaderHeight: number }

const Banners: FunctionComponent<{ visible: boolean; onBannerCountChange?: (count: number) => void }> = ({ visible, onBannerCountChange }) => {
  const userBanners = useUserBanners()

  const bannersList = userBanners
    ? [
        userBanners.DebtsStatusBanner,
        userBanners.CommissionPaymentBanner,
        userBanners.PushNotificationsBanner,
        userBanners.SocialNetworkBindBanner,
        userBanners.AddTelegramBanner,
      ]
    : []

  return <BannersInner bannersList={bannersList} key={`banners-${bannersList}`} onBannerCountChange={onBannerCountChange} visible={visible} />
}

const BannersInner: FunctionComponent<{
  bannersList: (FunctionComponent & WithVisibleHook)[]
  visible: boolean
  onBannerCountChange?: (count: number) => void
}> = ({ bannersList, visible, onBannerCountChange }) => {
  const dispatch = useAppDispatch()

  const visibleMap = new Map([AppBanner, ConnectionStatusBanner, ...bannersList].map(c => [c, c.useVisible(Date.now() / 1000)]))

  const AdditionalComponent = bannersList.find(b => !!visibleMap.get(b))
  const bannersCount = [!!visibleMap.get(AppBanner), !!visibleMap.get(ConnectionStatusBanner), !!AdditionalComponent].filter(f => !!f).length

  const clientEntered = useSelector(bannersVisibleSelector)
  const serverEntered = visible && bannersCount
  const entered = IS_BROWSER ? clientEntered : serverEntered

  useLayoutEffect(() => {
    onBannerCountChange?.(bannersCount)
  }, [bannersCount, onBannerCountChange, entered])

  if (!IS_BROWSER && serverEntered) dispatch(uiSetBannersVisible(true))

  useEffect(() => {
    if (!visible || !bannersCount) return

    dispatch(uiSetBannersVisible(true))

    return () => {
      setTimeout(() => {
        dispatch(uiSetBannersVisible(false))
      }, 50)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, bannersCount])

  return (
    <React.Fragment>
      <SlideUp animationTime={200} presumedHeight="200px">
        {entered && visibleMap.get(AppBanner) ? <AppBanner /> : null}
      </SlideUp>
      <SlideUp animationTime={200} presumedHeight="200px">
        {entered && visibleMap.get(ConnectionStatusBanner) ? <ConnectionStatusBanner /> : null}
      </SlideUp>
      <SlideUp animationTime={200} presumedHeight="200px">
        {entered && AdditionalComponent ? <AdditionalComponent /> : null}
      </SlideUp>
    </React.Fragment>
  )
}

type UserBannerName = 'DebtsStatusBanner' | 'CommissionPaymentBanner' | 'PushNotificationsBanner' | 'AddTelegramBanner' | 'SocialNetworkBindBanner'

const useUserBanners = () => {
  const loadedRef = useRef(false)
  const profile = useSelector(profileUserSelector)
  const [banners, setBanners] = useState<Record<UserBannerName, FunctionComponent & WithVisibleHook> | null>(null)

  useEffect(() => {
    if (!IS_BROWSER) return
    if (loadedRef.current) return
    if (profile && profile.account_type !== 'visitor') {
      loadedRef.current = true

      Promise.resolve().then(async () => {
        const banners = await Promise.all<[UserBannerName, FunctionComponent & WithVisibleHook]>(
          ensureType<Promise<[UserBannerName, FunctionComponent & WithVisibleHook]>[]>([
            IMPORT_MAP.components.DebtsStatusBanner().then(m => ['DebtsStatusBanner', m.DebtsStatusBanner]),
            IMPORT_MAP.components.CommissionPaymentBanner().then(m => ['CommissionPaymentBanner', m.CommissionPaymentBanner]),
            IMPORT_MAP.components.PushNotificationsBanner().then(m => ['PushNotificationsBanner', m.PushNotificationsBanner]),
            IMPORT_MAP.components.AddTelegramBanner().then(m => ['AddTelegramBanner', m.AddTelegramBanner]),
            IMPORT_MAP.components.SocialNetworkBindBanner().then(m => ['SocialNetworkBindBanner', m.SocialNetworkBindBanner]),
          ])
        )

        setBanners(Object.fromEntries(banners) as Record<UserBannerName, FunctionComponent & WithVisibleHook>)
      })
    }
  }, [profile])

  return banners
}
